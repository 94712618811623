.previewLotContainer {
  margin: 40px 50px 80px 0px !important;
}

.previewLotPage-container .previewLotContainer {
  margin-left: 50px !important;
}

.previewImagesWrapper > .mainImageColumn {
  margin-left: -40px !important;
  /* margin-right: 25px; */
  margin-top: 10px;
}

.previewImagesWrapper {
  margin-bottom: 56px !important;
}
.removeFlagBtn {
  margin-left: 20px;
}
.removeFlagBtn:hover {
  color: #ffffff;
}
.viewLotTitle {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  color: #000000;
}

.viewLotLabel {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 0;
}

.viewLotValue {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.viewLotCancelBtn {
  width: 184px;
  height: 56px;
  border: 1px solid #000000;
  background: #ffffff;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #000000;
}

.viewLotCancelBtn:hover,
.viewLotCancelBtn:focus,
.viewLotCancelBtn:active,
.nextPreviewBtn:hover,
.nextPreviewBtn:focus,
.nextPreviewBtn:active {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
  background: #000000 !important;
}

.viewLotEditBtn:hover,
.viewLotEditBtn:focus,
.viewLotEditBtn:active {
  border: 1px solid #000000 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
  background: #ffffff !important;
  color: #ffffff !important;
}

.viewLotEditBtn {
  width: 185px;
  height: 56px;
  background: #000000;
  border: none;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
}

.textBottomStyles {
  margin-bottom: 24px;
}

.lotButton {
  width: 59px;
  height: 33px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.04em;
  color: #333333;
}

.lotButton:focus,
.lotButton:hover,
.lotButton:active {
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  background: #ffffff !important;
  color: #333333 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.previewSideImages {
  width: 100px;
  height: 100px;
  margin-bottom: 80px;
  margin-left: 5px;
  object-fit: contain;
  cursor: pointer;
}

.mainPreviewImage {
  width: 100%;
  /* width : 761px; */
  height: 630px;
  object-fit: contain;
}

.previewBtnRow {
  display: flex;
  margin-bottom: 12px !important;
  justify-content: flex-end;
}

.previewRightBlock {
  margin-top: 115px;
}

.innerBlock {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 24px;
  /* height          : 356px; */
  /* width           : 478px; */
  width: 100%;
  /* height          : 100%; */
  flex-direction: column;
}

.currencyConverter {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #000000;
  float: right;
  margin-bottom: 16px;
}

.closePreviewBtn {
  width: 184px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #000000;
}

.closePreviewBtn:hover,
.closePreviewBtn:active,
.closePreviewBtn:focus {
  background: #ffffff !important;
  color: #000000 !important;
  border: 1px solid #000000 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.viewLotTabs > a {
  margin-right: 40px;
}

.viewLotTabs > a:focus {
  border-bottom: 2px solid #cccccc !important;
}

.contentStyles {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #333333;
  padding-top: 24px;
}

.currencyRow {
  flex-direction: column;
  padding-top: 24px;
  float: right;
}

.exitPreviewBtn {
  width: 161px;
  height: 56px;
  color: #000000;
  background: #ffffff;
}

.flagBtn {
  width: 99px;
  height: 56px;
  margin-left: 24px;
  color: #000000;
  background: #ffffff;
}

.removeFlagBtn {
  width: 171px;
  height: 56px;
  background: #000000;
  color: #ffffff;
}

.nextPreviewBtn {
  width: 151px;
  height: 56px;
  color: #ffffff;
  background: #000000;
  margin-left: 24px;
  border: none !important;
}

.prevPreviewBtn {
  width: 135px;
  height: 56px;
  color: #000000;
  background: #ffffff;
}

.btnText {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;
}

.previewPageBtns {
  margin-left: -48px !important;
  padding-right: 50px;
  padding-left: 50px;
  width: 100%;
  position: fixed;
  bottom: 0;
  justify-content: space-between;
  align-items: center;
  padding-top: 22px;
  padding-bottom: 22px;
  box-shadow: 0px -6px 4px rgb(0 0 0 / 5%);
}

.previewBackArrow {
  margin-left: -25px !important;
}
.mainImageColumn .spinner-border {
  margin-left: 50%;
}

.previewContent .public-DraftEditor-content[contenteditable="true"] {
  -webkit-user-modify: unset !important;
}
