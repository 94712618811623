.createCollectionContainer {
  margin-top: 64px;
  margin-left: 50px;
}

.headingRow {
  margin: 0;
  display: flex;
  align-items: center;
  margin-bottom: 48px;
  cursor: pointer;
}

.lotManagementHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  margin-left: 11px !important;
}

.formsRow {
  justify-content: center;
}

.accordion {
  width: 860px;
}

.card {
  width: 860px;
  height: auto;
  margin-bottom: 40px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.08);
}

.card-body {
  padding-left: 33px;
}

.exitBtn {
  width: 107px;
  height: 50px;
  border: 1px solid #000000;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.exitBtn:hover,
.exitBtn:active,
.exitBtn:focus {
  border: 1px solid #000000 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
  background-color: #ffffff !important;
  color: #000 !important;
}

.btnColumn {
  display: flex;
  justify-content: flex-end;
}

.leftBtnColumn {
  display: flex;
  justify-content: flex-end;
}

.rightBtnColumn {
  display: flex;
  justify-content: center;
}

.saveAddBtn {
  width: 201px;
  height: 50px;
  background: #000000;
  border-radius: 4px;
  border: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.saveAddBtn:not([disabled]):hover,
.saveAddBtn:active,
.saveAddBtn:focus {
  background: #000000 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.collectionsBtnRow {
  width: 860px;
  justify-content: space-between;
  margin-bottom: 340px !important;
  margin-top: 56px !important;
}

Button:disabled {
  background: #ccc !important;
  background-color: #ccc !important;
  color: #000 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.rdw-editor-main {
  min-height: 250px;
  padding-left: 33px;
  padding-right: 33px;
}

.collectionOverviewBody,
.conditionSaleBody {
  /* margin-bottom: 200px; */
}
.conditionSaleBody,
.collectionOverviewBody {
  background: rgba(0, 0, 0, 0.03);
  padding-bottom: 25px !important;
}

.conditionSaleBody > .editor,
.collectionOverviewBody > .editor {
  background: #fff;
}
.btnsUpperRow {
  justify-content: center;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-position: right calc(0.375em + 0.775rem) center;
}
.rdw-editor-wrapper .rdw-editor-main {
  max-height: 500px;
}
