.card {
  width: 100% !important;
  height: 442px;
  border: transparent;
  margin-bottom: 24px;
}

.auctionsCard {
  border: none !important;
  box-shadow: none !important;
}

.card-img {
  width: 430px;
  height: 300px;
}

.card-title {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #333333;
  height: 32px;
}

.card-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #333333;
  padding-top: 8px;
  margin-bottom: 0;
}

.card-body {
  padding: 0;
}

.browseBtnColumn {
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.reservePriceText {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #292929;
}

.actionBrowseBtn {
  width: 89px;
  height: 38px;
  border: 1px solid #333333;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* letter-spacing   : 0.04em; */
  color: #333333;
  background-color: transparent;
  float: right;
  padding: 0;
}

.actionBrowseBtn:hover {
  border: 1px solid #333333;
  background-color: #333333;
}

.actionBrowseBtn:active {
  border: 1px solid #333333 !important;
  background-color: #333333 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.actionBrowseBtn:focus {
  border: 1px solid #333333 !important;
  background-color: #333333 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.date {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #333333;
}

.lineStyles {
  margin-left: 8px;
  margin-right: 8px;
}
