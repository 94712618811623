.filterContainer {
  width: 276px;
}

.artistMenu {
  width: 100%;
  padding: 0;
  border: none;
}

.artistMenu:hover {
  background-color: transparent;
}

.artistMenu:focus {
  background-color: transparent;
}

.filterByText {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.44px;
  color: #19191d;
}
.filterByTextClearAll {
  cursor: pointer;
}
.filterByRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px !important;
}

.filterColumn {
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  padding: 0;
}

.filterDropdown {
  width: 90%;
  margin: 32px 15px 32px;
}

.filterDropdown > button {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #c4c4c4;
  color: #19191d;
  text-align: left;
  padding: 0 0 10px 8px;
  border-radius: 0;
}

.show > .btn-primary.dropdown-toggle {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #c4c4c4 !important;
  color: #000;
  text-align: left;
}

.dropdown > Button:active,
.dropdown > Button:focus,
.dropdown > Button:hover {
  background-color: transparent !important;
  border: none !important;
  border-bottom: 1px solid #c4c4c4 !important;
  box-shadow: none !important;
  color: #19191d !important;
}

.dropdown-toggle::after {
  display: none;
}

.upArrow {
  margin-left: 117px;
}

.form-check {
  margin-top: 12px;
  margin-bottom: 12px;
}

.form-check-label {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: #19191d;
}

.form-check-input {
  border: 2px solid #c4c4c4;
}

.parentFilter {
  margin-bottom: 30px;
  margin-top: 10px;
  height: auto;
  width: 90%;
  margin-left: 12px;
}

.filterHeader {
  border-bottom: 1px solid #c4c4c4;
  justify-content: space-between;
  height: 40px;
  padding: 0px 12px;
}

.headertext {
  width: 70%;
  float: left;
}
.arrowIcon {
  width: 30%;
  float: left;
}

.headertext {
  height: 40px;
  border-bottom: 2px solid #212121;
}

.upArrowFilter {
  margin-left: 70px;
}

.allListData {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-left: 10px;
  display: block;
  margin-top: 20px;
}

.listFilterItems {
  width: 100%;
  display: block;
  margin-top: 10px;
  /* white-space: nowrap; */
}

.listFilterItems > label {
  display: unset;
}

.listFilterItems .checkboxSelect {
  height: 18px;
  width: 18px;
  margin-right: 10px;
}

.range-slider {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.range-slider label {
  color: #fff;
  font-size: 13px;
}

.range-slider .number-group {
  flex: 1 0 auto;
  text-align: right;
  height: 30px;
  font-weight: 300;
  font-size: 13px;
  color: #fff;
}

.range-slider .number-group .number-input {
  width: 36px;
  height: 30px;
  text-align: center;
  color: #3faffa;
  background-color: #3d3e3f;
  border: 0;
}

.range-slider .number-group .number-input:first-of-type {
  margin-right: 7px;
}

.range-slider .number-group .number-input :last-of-type {
  margin-left: 7px;
}

.range-slider .number-group .number-input ::-webkit-outer-spin-button,
.range-slider .number-group .number-input ::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.range-slider .number-group .number-input :invalid,
.range-slider .number-group .number-input :out-of-range {
  border: 2px solid red;
}

.range-group {
  position: relative;
  flex: 0 0 100%;
  height: 47px;
}

.range-group .range-input {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-bottom: 0;
  -webkit-appearance: none;
  width: 100%;
  border-bottom: 0;
}

.range-group .range-input:focus {
  outline: 0;
}

.range-group .range-input::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  animation: 0.2s;
  /* background: rgb(153, 151, 151);  */
  background: #efefef;
  border-radius: 1px;
  /* opacity   : 0.1; */
  box-shadow: none;
  border: 0;
}

.range-group .range-input::-webkit-slider-thumb {
  z-index: 2;
  position: relative;
  height: 18px;
  width: 18px;
  opacity: 1;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  border: 1px solid #000000;
  -webkit-appearance: none;
  margin-top: -7px;
}

.range-group .range-input::-moz-range-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  animation: 0.2s;
  background: #333333;
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}

/* 			
			.range-group .range-input::-moz-range-thumb {
				z-index: 2; 
				position: relative; 
				box-shadow: 0px 0px 0px #000;
				border: 1px solid #333333;
				height: 18px; 
				width: 18px; 
				border-radius: 50%; 
				background: #333333; 
				cursor: pointer; 
			}
			
			.range-group .range-input::-ms-track {
				width: 100%; 
				height: 5px; 
				cursor: pointer; 
				animation: .2s; 
				background: transparent; 
				border-color: transparent; 
				color: transparent; 
			}
			
			.range-group .range-input::-ms-fill-lower,
			.range-group .range-input::-ms-fill-upper {
				background: #333333; 
				border-radius: 1px; 
				box-shadow: none; 
				border: 0; 
			}
			
			.range-group .range-input::-ms-thumb {
				z-index: 2; 
				position: relative; 
				height: 18px; 
				width: 18px; 
				border-radius: 50%; 
				background: #333333; 
				cursor: pointer; 
			} */

.filter {
  margin: 0 auto 30px;
  max-width: 100%;
}

.range-slider {
  width: 220px;
}

.number-group-Bottom {
  width: 100%;
  justify-content: space-between;
  margin: auto;
  margin-top: 20px;
}

.output-number-left {
  width: 50%;
  float: left;
  text-align: left;
}

.output-number-right {
  width: 50%;
  float: right;
  text-align: right;
}

.number-input {
  width: 80px;
  border: none;
}

.number-input:focus {
  border: none;
  outline: none;
}

.number-input-max,
.number-input-min {
  width: 55px;
}

.filterShowMore {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #929292;
  padding-left: 10px;
  padding-top: 11px;
  cursor: pointer;
}

.slider-handles div[role="slider"] {
  width: 16px !important;
  height: 16px !important;
  background: #ffffff !important;
  border: 0.8px solid #111111;
  box-shadow: none !important;
  outline: 0;
}

.two_side_slider {
  padding: 25px 0;
  width: calc(100% - 15px);
  margin: 0 auto;
}

.slider-tracks div {
  height: 1px !important;
  background: #000 !important;
}

.two_side_slider div:nth-child(2) {
  height: 1px !important;
}

.two_side_slider div div:nth-child(2) {
  height: 16px !important;
}

.value span {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
}
