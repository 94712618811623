.userslIstManagement {
  margin: 56px 70px;
}

.userslIstManagementRow {
  margin-bottom: 72px !important;
}

.headingText {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 39.0625px;
  line-height: 63px;
  color: #000000;
  padding: 0;
}

.createNewRoleBtn,
.createNewRoleBtn:hover,
.createNewRoleBtn:focus,
.createNewRoleBtn:active {
  width: 266px;
  height: 49px;
  background: #000000 !important;
  border-radius: 4px;
  border: none;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.plusIcon {
  margin-right: 8px;
}

.createBtnColumn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px;
}
.role_actionsIcons span {
  margin-right: 30px;
}

.role_actionsIcons {
  padding: 0px !important;
}
.edit_Admin_role {
  font-weight: normal;
  font-size: 25px;
  line-height: 34px;
  width: 860px !important;
  margin: auto;
  margin-bottom: 30px;
}
.createNewRoleHeadingROle {
  border-bottom: 1px solid #b0b0b0;
  height: 81px;
  padding-top: 20px;
  background: #fbfafa;
}
.createNewRoleEdit {
  background: #fbfafa;
  box-shadow: 1px 1px 5px rgb(0 0 0 / 8%);
}
.createNewRoleEdit input {
  background: #fbfafa;
}
.resetPasswordBtn {
  width: 160px;
  height: 40px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}
.role_actionsIcons span {
  cursor: pointer;
}
.centerTextRoles {
  width: 534px;
  height: 431px;
  margin: auto;
}
.basicUserInfoRoles {
  padding: 30px;
  background: #f2f2f2;
  box-shadow: 1px 1px 5px rgb(0 0 0 / 8%);
}

.firstColunText {
  width: 60%;
  float: left;
}
.basicUserInfoRoles .infoList {
  margin-top: 30px;
}
.GenderfirstColunText {
  width: 30%;
  float: left;
}
.bottomTextRoles {
  margin-top: 10px;
}
.infoListresetpass {
  text-align: center;
}
.resetEditBtn {
  width: 126px;
  height: 40px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  background: #000000;
  border: none;
  border-radius: 4px;
}

.resetEditBtn:hover {
  color: #ffffff;
  background: #000000;
  border: none;
  border-radius: 4px;
}
.articleHeadingRowParent {
  display: inline-block;
  width: 100%;
  margin-bottom: -20px;
}
.articleHeadingRowText {
  float: left;
  width: 300px;
}
.articleHeadingRowEdit {
  float: right;
}
.custom-switch-ButtonView {
  margin-left: 40px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #000000;
  border: none !important;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: unset !important;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: #000000 !important ;
  border-color: #000000 !important ;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #000000 !important;
}
