.live_auction_flex {
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.live_auction_page .auction_left_list::-webkit-scrollbar,
.live_auction_page .auction_box_2::-webkit-scrollbar,
.live_auction_page .auction_box_inner_list .reset::-webkit-scrollbar {
  width: 6px;
}

.live_auction_page .auction_left_list::-webkit-scrollbar-track,
.live_auction_page .auction_box_2::-webkit-scrollbar-track,
.live_auction_page .auction_box_inner_list .reset::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.live_auction_page .auction_left_list::-webkit-scrollbar-thumb,
.live_auction_page .auction_box_2::-webkit-scrollbar-thumb,
.live_auction_page .auction_box_inner_list .reset::-webkit-scrollbar-thumb {
  background: #888;
}

.live_auction_page .auction_left_list::-webkit-scrollbar-thumb:hover,
.live_auction_page .auction_box_2::-webkit-scrollbar-thumb:hover,
.live_auction_page
  .auction_box_inner_list
  .reset::-webkit-scrollbar-thumb:hover {
  background: #888;
}

#dataMessagesTextarea {
  display: none !important;
}

.live_auction_page .auction_box_1.active {
  left: 0;
}

.live_auction_page .auction_box_3.active {
  right: 0;
}

.live_auction_page .auction_box_3.active ~ .right_video_close_bar {
  right: 430px;
}

@media (max-width: 991px) {
  .live_auction_page .auction_box_1,
  .live_auction_page .auction_box_3 {
    position: absolute;
    left: -420px;
    width: 420px;
    z-index: 999;
    background: #fff;
    border-right: 1px solid rgba(0, 0, 0, 0.3);
    transition: all 0.5s ease-in-out;
  }

  .live_auction_page .auction_box_2 {
    width: 100%;
    border: 0;
    padding-top: 50px;
  }

  .live_auction_page .auction_box_3 {
    left: auto;
    right: -420px;
    border-right: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.3);
  }

  .image_gallery_slider.live_action_slider .image-gallery-thumbnail {
    height: 6px;
  }

  .image-gallery-left-nav,
  .image-gallery-right-nav {
    display: none;
  }

  .image_gallery_slider.live_action_slider .image-gallery-thumbnails {
    padding: 20px;
  }

  .live_auction_page .auction_box_1 .auction_left_list,
  .live_auction_page .auction_box_2,
  .live_auction_page .auction_box_3 .auction_box_inner_list ul {
    overflow-y: auto;
  }
}

@media (max-width: 600px) {
  .image_gallery_slider.live_action_slider
    .image-gallery-slide
    .image-gallery-image {
    height: 200px;
  }

  .live_auction_page .auction_box_2 {
    padding: 50px 15px;
  }

  .live_auction_page .auction_box_1,
  .live_auction_page .auction_box_3 {
    left: -100%;
    width: 100%;
  }

  .live_auction_page .auction_box_3 {
    right: -100%;
    left: auto;
    padding-top: 50px;
  }

  .right_video_close_bar {
    z-index: 9999;
    right: -26px;
  }

  .live_auction_page .auction_box_3.active ~ .right_video_close_bar,
  .live_action_right_sidebar_icon {
    right: 10px;
  }

  .live_action_left_sidebar_icon {
    left: 15px;
  }
}

#subscriber {
  height: 275px;
  width: 475px;
}
