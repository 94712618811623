.PurchaseDetailContainer {
  margin-top: 20px;
}
.chevronLeft {
  cursor: pointer;
  margin-bottom: 5px;
}

.bannerHeadingText {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 5px;
  color: #333333;
}

.PurchaseDetailsRow {
  width: 1340px;
  background: #fbfbfb;
  border-radius: 4px;
}

.DispalyLineCol {
  display: inline;
}

.PurchaseDetailsCol {
  display: -webkit-inline-box;
  padding-top: 20px;
}

.purchaseImageDiv {
  width: 316px;
  height: 210px;
  background: #e9e9e9;
  border-radius: 4px;
}

.PurchaseImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.purchaseImageDetails {
  width: 100%;
  margin-left: 20px;
}
.PurchaseDetailsId {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.07em;
  color: #000000;
  width: 102px;
  height: 26px;
}

.PurchaseAuctionName {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  color: #333333;
  width: 389px;
}
.PurchaseAuctionName span {
  font-weight: bold;
}

.PurchaseLotName {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  width: 394px;
}
.PurchaseLotName span {
  font-weight: bold;
}
.purchaseAmount {
  width: 107px;
  height: 33px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
  font-weight: bold;
}

.lineStylesPurchase {
  width: 196px;
  height: 0px;
  border: 1px solid #e2e2e2;
  transform: rotate(90deg);
  margin-left: -90px;
  margin-bottom: -231px;
}

.PurchasePaymentDiv {
  width: 412px;
  height: 93px;
  padding-top: 50px;
}

.PurchasePaymentCol {
  display: flex;
  margin-left: -80px;
  margin-top: 43px;
}

.OrderDateDiv {
  width: 83px;
  height: 47px;
  float: left;
}

.OrderDataText {
  width: 78px;
  height: 17px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.OrderDate {
  width: 83px;
  height: 26px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  color: #000000;
}

.PaymentStatusDiv {
  width: 103px;
  height: 47px;
  float: right;
}

.PaymentStatusText {
  width: 103px;
  height: 17px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.CompletedText {
  /* width       : 75px; */
  height: 26px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  color: #000000;
}

.DownloadInvoiceBtn {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 147px;
  height: 30px;
  background-color: #fbfbfb;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;
  flex-direction: row;
  margin: 0px 29px 0px 0px;
  float: right;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.DeliveryAddressRow {
  width: 430px;
  height: 215px;
  background: #fbfbfb;
  border-radius: 4px;
}

.DeliveryAddressCol {
  margin-left: 15px;
}

.DeliveryAddressText {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
}

.PersonName {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
}

.FullAddressText {
  width: 243px;
  height: 44px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: rgba(51, 51, 51, 0.8);
}

.PhoneNumber {
  width: 210px;
  height: 25px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  color: #000000;
}

.PurchaseDetailContainer .spinner-border {
  margin-left: 50%;
}
