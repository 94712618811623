.login_input_page {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.login_btn_page {
  background-color: #4caf50;
  color: white;
  padding: 7px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  font-size: 22px;
  border-radius: 4px;
}

.login_btn_page:hover {
  opacity: 0.8;
}

.login-page-container {
  padding: 16px;
}
.loginManagement {
  width: 500px;
  margin-top: 30px;
  margin-left: 32%;
}
.noDataerrorMessage {
  color: red;
  margin: 10px 0px;
}
