.auction_box_2 {
  width: 43%;
  padding: 30px;
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  overflow-x: hidden;
  height: 100vh;
  overflow-y: hidden;
}

.auction_box_2:hover {
  overflow-y: auto;
}

.image_gallery_slider.live_action_slider .image-gallery-slide.center {
  padding-right: 0;
}

.image_gallery_slider.live_action_slider
  .image-gallery-slide
  .image-gallery-image {
  width: 100%;
  height: 400px;
  max-height: unset;
  object-fit: contain;
}

.image_gallery_slider.live_action_slider
  .image-gallery-thumbnails
  .image-gallery-thumbnails-container {
  justify-content: center;
}

.image-gallery-left-nav svg,
.image-gallery-right-nav svg {
  display: none;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  width: 25px;
  height: 25px;
  padding: 0 !important;
  filter: none;
  top: auto;
  transform: translateY(0%);
  bottom: -100px;
}

.image-gallery-left-nav::before,
.image-gallery-right-nav::before {
  content: "";
  position: absolute;
  background-image: url(../../../assets/image/ar1.png);
  width: 25px;
  height: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-gallery-left-nav::before {
  background-image: url(../../../assets/image/ar2.png);
}

.image_gallery_slider.live_action_slider .image-gallery-thumbnail {
  margin: 0 10px;
  border-radius: 4px;
  height: 80px;
}

.image_gallery_slider.live_action_slider .image-gallery-thumbnails {
  padding: 40px 30px;
}

.live_action_slider.image_gallery_slider
  .image-gallery-thumbnails-wrapper::before {
  display: none;
}

.live_action_slider.image_gallery_slider .image-gallery-thumbnails-wrapper {
  margin-top: 0;
}

.live_action_slider.image_gallery_slider .image-gallery-thumbnail {
  width: 100px;
}

.auction_box_2 .auction_banner_cont_top,
.currentBidHeading {
  font-weight: 500;
  font-size: 24px;
  color: #000000;
  margin: 8px 0px;
  font-family: "Noto Serif", serif;
}

.inlineDiv {
  display: flex;
}

.paddingRightStyle {
  padding-right: 16px;
}

.nextLotBtn {
  width: 130px;
  height: 50px;
  border: 1px solid #000000;
  border-radius: 4px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
}

.liveCloseLotBtn {
  width: 135px;
  height: 50px;
  background: #333333;
  border-radius: 4px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.priceWithBtn {
  display: flex;
  justify-content: space-between;
}

.currentBidBox {
  background: #f5f5f5;
  border: 1px solid rgba(51, 51, 51, 0.05);
  border-radius: 4px;
  height: 267px;
  margin-top: 122px;
  padding: 20px;
}

.biddingDetailsLine {
  display: flex;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  color: #333333;
  margin-top: 23px;
  margin-bottom: 31px;
}

.biddingDetailsLine span:not(:last-child) {
  padding-right: 17px;
}

.currentBidAmt {
  text-align: center;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 42px;
  color: #000000;
}

.liveChooseWinnerBtn {
  text-align: center;
  width: 214px;
  height: 50px;
  background: #333333;
  border-radius: 4px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.liveChooseWinnerBtn:hover,
.liveChooseWinnerBtn:active,
.liveChooseWinnerBtn:focus {
  color: #ffffff !important;
}

.liveButtonsSection {
  display: flex;
  justify-content: center;
}

.auction_banner_cont_top .lots_cost span {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  display: block;
  font-family: "Poppins", sans-serif;
}

.auction_banner_cont_top .lots_cost .strong {
  font-weight: 600;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.auction_banner_cont_tabs .product_description {
  padding: 30px 0 0 0;
}

.auction_banner_cont_tabs .product_description .prd_des_list {
  width: 100%;
}

.auction_banner_cont_tabs
  .product_description
  .prd_des_list
  .nav-item:first-child {
  margin-left: 0;
}

.auction_banner_cont_tabs .product_description .tab-content {
  padding: 0;
}

.auction_banner_cont_tabs
  .product_description
  .tab-content
  .prdct_des_tab_cont {
  padding: 20px 0px;
}

.auction_banner_cont_tabs
  .product_description
  .tab-content
  .prdct_des_tab_cont
  p {
  padding-bottom: 25px;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 26px;
  color: #333333;
  font-family: "Noto Serif", serif;
  font-weight: 400;
}

.auction_banner_cont_tabs
  .product_description
  .tab-content
  .prdct_des_tab_cont
  h4 {
  font-size: 20px;
  color: #333333;
  font-weight: 400;
  font-family: "Noto Serif", serif;
  margin-bottom: 0;
}

.live_action_slider .image-gallery-slide.center {
  width: 100% !important;
  height: 500px !important;
}

.live_action_slider .image-gallery-slide.center img {
  width: 80% !important;
}

@import "~react-image-gallery/styles/css/image-gallery.css";

.image_gallery_slider .image-gallery-slide.center {
  /* padding-right: 16.5%; */
  width: 900px;
  height: 555px;
  overflow: hidden;
  /* height: 100%; */
}

.image_gallery_slider .image-gallery-slide.center img {
  max-height: 100%;
  max-width: 100%;
  object-fit: fill;
}

.image_gallery_slider .image-gallery-slide .image-gallery-image {
  object-fit: cover;
}

.image_gallery_slider .image-gallery-thumbnails {
  /* background: #fff; */
}

.image_gallery_slider .image-gallery-thumbnails-wrapper::before {
  content: "";
  position: absolute;
  right: -1px;
  top: 0px;
  height: 100%;
  width: 100%;
  background: #fff;
}

/* .image_gallery_slider .image-gallery-thumbnails-wrapper{
    margin-top: -27px;
} */

.image_gallery_slider .image-gallery-thumbnail {
  height: 100px;
  /* width     : 214px; */
  border: 0;
  margin-right: 45px;
  border-radius: 4px 4px 0px 0px;
  background: #c4c4c4;
  overflow: hidden;
}

.image_gallery_slider .image-gallery-thumbnail:last-child {
  margin-right: 0;
}

.image_gallery_slider .image-gallery-thumbnail .image-gallery-thumbnail-inner {
  height: 100%;
}

.image_gallery_slider .image-gallery-thumbnail .image-gallery-thumbnail-image {
  object-fit: cover;
  height: 100%;
}

.image_gallery_slider .image-gallery-thumbnails {
  padding: 40px 50px;
}

.image_gallery_slider
  .image-gallery-thumbnails
  .image-gallery-thumbnails-container {
  text-align: left;
  display: flex;
}

.image_gallery_slider .image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  border: 0;
}

.bid_img_gallery_slider.image_gallery_slider
  .image-gallery-slide.center
  .image-gallery-image {
  opacity: 1;
}

.bid_img_gallery_slider.image_gallery_slider
  .image-gallery-slide
  .image-gallery-image {
  opacity: 0.6;
  transition: opacity 0.1s ease-in-out;
}

/* media query css start */

@media (max-width: 1200px) {
  .image_gallery_slider .image-gallery-thumbnail {
    width: 165px;
  }
}

@media (max-width: 991px) {
  .image_gallery_slider .image-gallery-thumbnails {
    padding: 12px;
  }

  .image_gallery_slider
    .image-gallery-thumbnails
    .image-gallery-thumbnails-container {
    justify-content: center;
  }

  .image_gallery_slider .image-gallery-thumbnail {
    width: 16px;
    height: 6px;
    margin: 6px;
    border-radius: 10px;
  }

  .image_gallery_slider
    .image-gallery-thumbnail
    .image-gallery-thumbnail-image {
    display: none;
  }

  .image_gallery_slider .image-gallery-thumbnail.active {
    background: #767676;
  }

  .image_gallery_slider .image-gallery-thumbnails-wrapper {
    margin-top: 0;
  }
}
.endAuctionBtn {
  border: 1px solid #000;
}
.endAuctionBox p {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 42px;
}
.showEndAuctionSection {
  width: 90%;
  background-color: #f5f5f5;
  margin: auto;
  margin-top: 185px;
  height: 200px;
  padding: 50px;
  text-align: center;
}
/* media query css end */
