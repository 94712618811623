.AngleUpIcon {
  float: right;
}

.lotDescriptionBody {
  padding-left: 33px !important;
  padding-right: 33px !important;
}

.saveContinue {
  width: 214px;
  height: 50px;
  background: #000000;
  border: none;
  border-radius: 4px;
  margin-top: 48px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.saveContinue:focus,
.saveContinue:hover {
  background: #000000 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.rdw-image-modal-header > span:nth-child(1) {
  display: none;
}
.rdw-image-wrapper > .rdw-image-modal {
  left: -100px;
  width: 300px;
}
.rdw-image-wrapper > .rdw-image-modal > .rdw-image-modal-header {
  display: none;
}
.rdw-image-modal
  .rdw-image-modal-upload-option
  .rdw-image-modal-upload-option-label {
  overflow-x: scroll;
}

.lotDescriptionBody,
.conditionReportBody {
  /* margin-bottom: 200px; */
}

.btnAlign {
  float: right;
  margin-right: -40px !important;
}
.errorEditorlot {
  color: red;
}
