.AngleUpIcon {
  float: right;
}

.mediaContainer {
  margin: 47px 100px 34px;
  padding-left: 0;
}

.imageBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.first {
  width: 100%;
  height: 380px;
  background: #f7f7f7;
  border: 1px dashed #de0000;
  border-radius: 9px;
  margin-bottom: 34px !important;
}
.mediaContainer .first img {
  max-width: 659px;
  max-height: 316px;
  object-fit: contain;
}

.mediaContainer .imageBlockSmall img {
  max-width: 280px;
  max-height: 190px;
  object-fit: contain;
}
.videoContentStyles {
  width: 100%;
  height: 380px;
  background: #f7f7f7;
  border: 1px dashed #3a3a3a;
  border-radius: 9px;
  margin-bottom: 34px !important;
}

.second,
.third {
  width: 314px;
  height: 248px;
  background: #f7f7f7;
  /* border       : 1px dashed #DE0000; */
  border: 1px dashed #3a3a3a;
  border-radius: 4px;
}

.makersMark,
.damage,
.anotherImage,
.supportedDoc {
  width: 314px;
  height: 248px;
  background: #f7f7f7;
  border: 1px dashed #3a3a3a;
  border-radius: 4px;
}

.dualImages {
  justify-content: space-between;
  margin-bottom: 34px !important;
}
.dualImagesError {
  justify-content: space-between;
  margin-bottom: 20px !important;
}

.addImageText {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.labelContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.requirementTipsRow {
  justify-content: space-between;
}

.requirementColumn,
.tipsColumn {
  padding: 0;
}

.requirementHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
}

.requirementList {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 26px;
  padding: 0 !important;
  color: #333333;
  list-style: none;
  flex-direction: column;
}

.tipsHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  padding-left: 23px;
}

.tipsList {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 26px;
  color: #333333;
}

.saveContinue {
  width: 214px;
  height: 50px;
  background: #000000;
  border: none;
  border-radius: 4px;
  margin-top: 48px;
  margin-right: 34px !important;
  margin-bottom: 32px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.saveContinue:focus,
.saveContinue:hover {
  background: #000000 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.redTextFront {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #ff4848;
  margin-bottom: 0;
  position: relative;
  top: 70px;
}

.redText {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #ff4848;
  margin-bottom: 0;
  position: relative;
  top: 43px;
}
.blackText {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 0;
  position: relative;
  top: 43px;
}

.mediaContainer > .btnRow {
  margin-right: -100px !important;
  float: right;
}
.errorImageFIle {
  color: red;
  text-align: center;
  margin-bottom: 50px;
  margin-top: -15px;
}

.errorImageFIle2ndRow {
  color: red;
  text-align: center;
  margin-bottom: 2px;
}

.deleteImageIcon {
  align-self: end;
  position: absolute;
  cursor: pointer;
}
