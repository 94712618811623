.orderMgmtContainer {
  padding-left: 75px;
  padding-right: 70px;
}
.TopContentDiv {
  display: flex;
}

.orderMgmtHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 39.0625px;
  line-height: 63px;
  color: #000000;
  margin-top: 56px;
  margin-bottom: 56px;
}

.filterByDropdown > button {
  width: 148px;
  height: 49px;
  background: #ffffff;
  color: #000000;
  border: 1px solid #000000 !important;
  border-radius: 4px;
}

.filterByDropdown > button:hover,
.filterByDropdown > button:active,
.filterByDropdown > button:focus {
  background: #ffffff !important;
  border: 1px solid #000000 !important;
  border-radius: 4px !important;
}

.filterByDropdown > .show > .btn-primary.dropdown-toggle {
  border: 1px solid #000 !important;
}

.orderMgmtContainer > .show > .btn-primary.dropdown-toggle {
  border-bottom: 1px solid #000000 !important;
}

.orderMgmtContainer > .show > div > .dropdown-item,
.orderMgmtContainer
  > .show
  > div
  > .dropdown-item
  > .dropdownText
  > .form-check-label {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}

.sameStatusText {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 25px;
  color: red;
  text-transform: capitalize;
}

/* .filterByDropdown>button>.dropdown-item {

} */

.FilterByText {
  display: inline-block;
  width: 100px !important;
  overflow: hidden;
  white-space: nowrap;
  width: 87px important;
  height: 29px;
  font-family: "Noto Serif";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 29px;
  margin: 0px 6px;
  text-overflow: ellipsis;
}

.chevronDown {
  padding-left: 7px;
}

/*=======my change 11-05-2021 dropdown =========*/
.dropdownText {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  margin-left: 9.25px;
  margin-bottom: 0px !important;
}
.dropdownTexts {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  margin-bottom: 19px;
}

.dropdownDivider {
}
.dropdwonOrdermanagement.show {
  width: 195px;
  height: 402px;
  padding-left: 10px;
}
.dropdwonOrdermanagement.show > .btn-primary.dropdown-toggle:active,
.dropdwonOrdermanagement.show > .btn-primary.dropdown-toggle:hover,
.dropdwonOrdermanagement.show > .btn-primary.dropdown-toggle:focus {
  border: 1px solid #c4c4c4 !important;
}

.dropdownTextHeader {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}
/*=======my change 11-05-2021 =========*/

.orderMgmtTable {
  background: #f9f9f9;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
}

.orderMgmtTable > thead > tr > th {
  border: none;
  border-bottom: 1px solid rgba(51, 51, 51, 0.3);
}

.orderMgmtTable > tbody > tr {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.19);
  border-radius: 2px;
}

.orderMgmtTable > tbody > tr > td {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  color: #333333;
  height: 74px;
  padding-top: 23px;
}
.odvContainer {
  /* margin: 36px 50px 50px; */
  margin-top: 50px;
  margin-left: -25px;
  margin-right: -20px;
}
.orderMgmtSideHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #333333;
}
.orderMgmtSideHeading span {
  cursor: pointer;
}
.orderMgmtTable > tbody > tr > td:first-child {
  text-decoration: underline;
  cursor: pointer;
}

.orderMgmtTable > tbody > tr > td:nth-child(5) {
  padding-left: 22px;
}

/* .orderMgmtTable>tbody>tr>td:last-child {
    padding-left: 30px;
} */

.red {
  color: #b60a1c !important;
}

.green {
  color: #309143 !important;
}

.actionsDropdown {
  justify-content: flex-start;
  display: flex;
  margin: 0 !important;
}

.actionsDropdown > Button,
.actionsDropdown > Button:hover,
.actionsDropdown > Button:focus,
.actionsDropdown > Button:active {
  background-color: transparent;
  border: none !important;
  border-bottom: none !important;
}

.actionsDropdown > button > .show > .btn-primary.dropdown-toggle {
  border-bottom: none !important;
}

.arrowBox {
  width: 31.06px;
  height: 30px;
  background: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.19);
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paginationBoxes {
  margin-top: 48px !important;
  margin-bottom: 56px !important;
  display: flex;
  justify-content: center;
}

.paginationNumbers {
  margin-right: 7.82px;
}

.paginationBoxes > .arrowBox:first-child {
  margin-right: 13.82px;
}

.paginationBoxes > .arrowBox:nth-child(2) {
  margin-right: 31.17px;
}

.paginationBoxes > .arrowBox:nth-last-of-type(2) {
  margin-left: 31.17px;
}

.paginationBoxes > .arrowBox:last-child {
  margin-left: 13.82px;
}

.filterByBlockCol {
  margin-top: 66px;
  margin-bottom: 56px;
}

.SearchComponentCol {
  margin-top: 62px;
  margin-bottom: 56px;
}

.ChangeOrderText {
  width: 259px;
  height: 41px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 40.45px;
}

.OnHoldBlock {
  width: 220px;
  height: 55px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 4px;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-left: 24px;
}

.OnHoldText {
  border: none;
  width: 64px;
  height: 26px;
  font-family: Noto serif;
  font-style: normal;
  font-size: 16px;
  line-height: 25.89px;
  text-align: left;
  vertical-align: top;
}

.HoldBySelect {
  border: none;
  width: 204px;
  height: 36px;
  font-family: Noto serif;
  font-style: normal;
  font-size: 16px;
  line-height: 25.89px;
  text-align: left;
  vertical-align: top;
}

.HoldBySelect:focus,
.HoldBySelect:hover,
.HoldBySelect:active {
  border: none !important;
  box-shadow: unset !important;
  /* min-width: 150px !important;
    width: auto !important; */
}

.HoldBySelect:focus {
  min-width: 164px !important;
}

.divider {
  font-size: 0.01em;
  background: #e5e5e5;
  margin-top: 10px;
}

.firstSelection {
  padding-top: 10px;
}

/* pagination */
.pageNumber {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  padding: unset !important;
  cursor: pointer;
}

.pageNumber > li {
  width: 31.06px;
  height: 30px;
  background: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.19);
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 7px !important;
}
.pageNumber > li.active {
  background-color: #000000;
  color: #ffffff;
}

.paginationNextBtn {
  margin-left: 7px !important;
}

button.arrowBox:disabled {
  cursor: no-drop;
}

/* Tooltip container */
.filterByDropdown {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.filterByDropdown .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  /* height: 60px; */

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 5%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.filterByDropdown .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.filterByDropdown:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
