.header {
  height: 113px;
  left: 0%;
  right: 0%;
  top: 0px;
}

.appLogo {
  padding-left: 35px;
  max-height: 35px;
}
.upperHeader {
  margin: 0;
  height: 32px;
  background-color: #1b1b1b;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.upperHeaderLinks {
  display: flex;
  font-size: 12px;
  line-height: 18px;
  margin: 0 0 0 5.2em;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
}

.upperHeaderLinks > li {
  margin-left: 40px;
}

.lowerHeader {
  height: 81px;
  background-color: #ffffff;
  margin: 0;
  width: 100%;
  align-items: center;
}
.logoutButtonNAme {
  height: 38px;
  margin-top: 15px;
}
.lowerHeaderLinks {
  display: flex;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #4e4e4e;
  margin: 0;
}

.lowerHeaderLinks > li {
  margin-left: 40px;
}

.headerIcons {
  display: flex;
  justify-content: flex-end;
}

.headerIcons > svg {
  margin: 20px;
}

.searchIcon {
  margin-right: 35px;
}
