.auctionContainer {
  margin-left: 50px;
  margin-right: 50px;
}

.auctionManagementRow {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}

.gridViewWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.bottomPadding {
  margin-bottom: 60px;
}
