.ArticlesContainer {
  margin-left: 50px;
  margin-right: 50px;
}

.ArticlesManagementRow {
  margin-top: 76px !important;
  margin-bottom: 56px !important;
}

.headingText {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 39.0625px;
  line-height: 63px;
  color: #000000;
}
.articleClass {
  margin-right: 70px;
}
.createArticleBtn,
.createArticleBtn:hover,
.createArticleBtn:focus,
.createArticleBtn:active {
  width: 164px;
  height: 50px;
  background: #000000 !important;
  border-radius: 4px;
  border: none;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.plusIcon {
  margin-right: 8px;
}

.createBtnColumn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.viewMoreBtnRow {
  justify-content: center;
}

.viewMoreBtn {
  border: none;
  background: #000000;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  padding: 14px 32px;
  margin-top: 42px !important;
  margin-bottom: 84px !important;
}

.viewMoreBtn:focus,
.viewMoreBtn:active,
.viewMoreBtn:hover {
  background: #000000 !important;
  color: #ffffff !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}
