.imagePopupContainer {
  display: block !important;
  margin-top: 5px;
  text-align: center;
  padding-bottom: 0px !important;
  min-height: 172px !important;
}
.registertoBidHead {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
}
.subtextPageTitle {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
}

.imageCrossIcon {
  float: right;
  cursor: pointer;
  padding-right: 20px;
  font-weight: bold;
}
.subtextPage {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
}
.subtextPage label {
  cursor: pointer;
}
.bidBottomText {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}
.imageUrlForPopupName {
  margin: 50px;
}
.imageUrlForPopupName img {
  max-width: 100%;
}
