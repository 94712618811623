.bannerContainer {
  width: 100%;
  height: 365px;
  background: #ececec;
  padding: 24px 50px 16px;
  margin-bottom: 31px;
}

.bannerRow {
  height: 100%;
}

.columnStyles {
  padding: 0;
}

.auctionTypeText {
  text-transform: capitalize;
}

.auctionClosed {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #333333;
}

.btnTopRow {
  justify-content: space-between;
}

.btnBottomRow {
  position: absolute;
  bottom: 0px;
  width: 100%;
  justify-content: flex-end;
}

.bannerTitle {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  line-height: 63px;
  color: #333333;
}

.date {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #333333;
}

.time,
.onlineAuction {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #333333;
}

.lineStyles {
  margin-left: 8px;
  margin-right: 8px;
}

.editBtn {
  width: 126px;
  height: 50px;
  background: #000000;
  border: none;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  /* margin-right: 22px; */
}

.editBtn:hover,
.editBtn:focus,
.publishBtn:hover,
.publishBtn:focus {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
  background: #000000 !important;
}

.publishBtn {
  width: 155px;
  height: 50px;
  background: #000000;
  border: none;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.createLotBtn,
.createLotBtn:hover,
.createLotBtn:focus,
.createLotBtn:active {
  width: 164px;
  height: 50px;
  background: #000000 !important;
  border-radius: 4px;
  border: none;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}
