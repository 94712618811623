.userInformationContainer {
  justify-content: center;
  margin-bottom: 50px !important;
}

.userInformation {
  width: 640px;
  margin: 37px 356px 21px !important;
}

.userInfoHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #000000;
}

.userInformationCard {
  width: 640px;
  height: 507px;
  background: #f2f2f2;
  border-radius: 4px;
  padding: 56px 40px 35px;
}

.mobileNumber,
.roleColumn {
  padding: 0 0 0 61px;
}
