.card {
  /* width: 430px; */
  width: 100% !important;
  height: auto;
  border: transparent;
  margin-bottom: 24px;
}

.card-img {
  width: 430px;
  height: 300px;
}

.card-title {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #333333;
}

.card-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #333333;
  padding-top: 8px;
}

.articleEditBtn {
  width: 88px;
  height: 38px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 0.04em;
  color: #333333;
  border-radius: 4px;
  border: 1px solid #333333;
  background: #fff;
  text-align: center;
  margin-top: 16px;
}

.articleEditBtn:hover,
.articleEditBtn:active,
.articleEditBtn:focus {
  background: #ffffff !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
  border: 1px solid #333333 !important;
  color: #000000 !important;
}

.card-body {
  padding: 0;
}

.showOnHomeBtn {
  width: 149px;
  height: 38px;
  border: 1px solid #333333;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.04em;
  color: #333333;
  background-color: transparent;
  float: left;
  padding: 0;
  margin-top: 16px;
}

.showOnHomeBtn:hover {
  border: 1px solid #333333;
  background-color: #333333;
}

.showOnHomeBtn:active {
  border: 1px solid #333333 !important;
  background-color: #333333 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.showOnHomeBtn:focus {
  border: 1px solid #333333 !important;
  background-color: #333333 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.articleDate {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #292929;
}

.favourite {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
}

.share {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  margin-left: 43px;
}

.lineStyles {
  margin-left: 8px;
  margin-right: 8px;
}

.ArticleImageMain {
  width: 430px;
  height: 300px;
}

.ArticleImageMain img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
