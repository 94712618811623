.angleUpIcon {
  float: right;
}
.invalid-feedbackText {
  font-size: 80%;
  color: #dc3545;
}

.lotDetailsBody {
  padding-left: 33px !important;
  padding-right: 33px !important;
}

.form-label {
  margin-top: 30px;
  width: 100%;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
}
.errrorFieldsValue {
  color: red;
  margin-top: 5px;
}
.form-control {
  width: 589px;
}

.form-check {
  padding-top: 11px;
}

.currencySelect {
  width: 108px;
}

.mediumFields {
  width: 280px;
}

.estimatedPriceLabel {
  width: 18%;
}

.rangeFields {
  align-items: center;
}

.smallFields {
  width: 143px;
}

.measurementFieldsspacing {
  width: 190px;
  margin-right: 8px;
}

.measurementFieldsspacingWeight {
  width: 290px;
  margin-right: 8px;
  text-align: right;
}

.measurementLabel {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 26px;
  color: #000000;
  margin-top: 0px;
}

.toText {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  margin: 0 15px;
  padding-left: 10px;
}
.toTextPadding {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  margin: 0 15px;
  margin-bottom: 25px !important;
  padding-left: 5px;
}

.measurementsType {
  padding-right: 39px;
  padding-top: 0px !important;
  margin-top: 0px !important;
}

.saveContinue {
  width: 214px;
  height: 50px;
  background: #000000;
  border: none;
  border-radius: 4px;
  margin-top: 48px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.saveContinue:focus,
.saveContinue:hover {
  background: #000000 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.lotDetailsCheckbox > label {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  color: #000000;
}

.lotRestrictedRadio {
  padding-top: 0 !important;
  margin-top: 8px !important;
  margin-bottom: 16px !important;
  margin-right: 25.5px !important;
}

.mentionHere {
  margin-top: 0px;
}
