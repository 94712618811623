.articlePreviewHeadingRow {
  margin: 0;
  display: flex;
  align-items: center;
  margin-bottom: 32px !important;
}

.createArticleContainer > .createArticle {
  width: 860px !important;
  margin: auto;
}
.createArticleHeaderContainer {
  height: 300px;
  width: 100%;
  padding: 40px;
  background: #f5f5f5;
}
.btnsRowPreview {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  justify-content: space-between;
}
.Close_Preview {
  width: 138px;
  height: 50px;
  border: 1px solid #000000;
  background-color: #000000 !important;
  color: #fff;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.Close_Preview:hover {
  color: #fff !important;
  border: 1px solid #000000 !important;
}
.editArticle_Preview {
  margin-left: 800px;
  width: 124px;
  height: 50px;
  border: 1px solid #000000;
  background-color: #fff !important;
  color: #000000;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.editArticle_Preview:hover {
  border: 1px solid #000000;
  background-color: #fff !important;
  color: #000000;
}
.closeArticle_Preview {
  width: 178px;
  height: 50px;
  border: 1px solid #000000;
  background-color: #fff !important;
  color: #000000;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.closeArticle_Preview:hover {
  border: 1px solid #000000;
  background-color: #fff !important;
  color: #000000;
}

.contentMgmtHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  margin-left: 5px;
  cursor: pointer;
}
.textHeaderPreview {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
}
.previewArticleBody {
  width: 860px !important;
  margin: auto;
  padding: 20px 0px !important;
  font-family: Noto Serif;
  font-style: normal;
}
.previewArticle {
  box-shadow: none;
}
.headerPreviewContent {
  margin-top: 20px !important;
  height: auto;
}
.headerContentViewArticle div,
.headerContentViewArticle img {
  max-width: 100% !important;
}
.headerContentViewArticle .public-DraftEditor-content[contenteditable="true"] {
  -webkit-user-modify: unset;
}
.PreviewTitle {
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 25px;
  line-height: 40px;
}
.PreviewAuthor {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}
.PreviewAdescCon {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}
.PreviewdisbaledText {
  font-size: 16px;
  line-height: 26px;
  color: #333333;
  opacity: 0.5;
}
