.angleUpIcon {
  float: right;
}

.formHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
}

.conditionSaleBody {
  padding-left: 33px !important;
  padding-right: 33px !important;
}

.editorHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  margin-top: 24px;
  margin-bottom: 12px;
}
