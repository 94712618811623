.card {
  width: 430px;
  height: 442px;
  border: transparent;
}

.lotsCardStyles {
  box-shadow: none !important;
  margin-bottom: 48px !important;
}

.lotBtn {
  width: 59px;
  padding: 0;
  height: 33px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  margin: 8px 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.04em;
  color: #333333;
}

/* .dropdown-toggle,
.dropdown-toggle:hover,
.dropdown-toggle:focus
    {
    text-decoration          : none;
    text-decoration-thickness: unset !important;
    text-decoration-style    : unset !important;
    text-decoration-color    : unset !important;
} */

.customDropdown {
  justify-content: flex-end;
  display: flex;
  margin-top: 5px !important;
  margin: 0 !important;
}

.customDropdown > Button,
.customDropdown > Button:hover,
.customDropdown > Button:focus,
.customDropdown > Button:active {
  background-color: transparent;
  border: none !important;
  border-bottom: none !important;
}

.threeDotsCol > .dropdown > Button:active,
.threeDotsCol > .dropdown > Button:focus,
.threeDotsCol > .dropdown > Button:hover {
  background-color: transparent;
  border: none !important;
  border-bottom: none !important;
}

.threeDots > .show > .btn-primary.dropdown-toggle {
  background-color: transparent;
  border: none;
  border-bottom: none !important;
  color: #000;
  text-align: left;
}

.card-img {
  /* width : 430px;
    height: 300px; */
  width: 302px;
  height: 300px;
}

.card-title {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #333333;
  padding-top: 8px;
  margin: 0;
}

.lotTitleHeight {
  /* height: 52px !important; */
  padding-bottom: 10px;
}

.card-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #333333;
  padding-top: 8px;
}

.card-body {
  padding: 0;
}

.priceBidRow {
  margin: 0;
}

.priceEstimate {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0;
}

.estimate {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.5);
  margin: 0;
}

.price {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  margin: 0;
}

.bitBtnContainer {
  padding: 0;
}

.lotsEditBtn {
  width: 79px;
  height: 37px;
  border: 1px solid #333333;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #333333;
  float: right;
  margin-top: 16px;
  padding: 0;
}

.bidIcon {
  padding-right: 6px;
}

/* .lotsEditBtn:hover,
.lotBtn:hover {
    border          : 1px solid #333333;
    background-color: #333333;
} */

.lotsEditBtn:hover,
.lotBtn:hover,
.lotsEditBtn:active,
.lotBtn:active,
.lotsEditBtn:focus,
.lotBtn:focus {
  border: 1px solid #333333 !important;
  background-color: #333333 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.topRow {
  justify-content: space-between;
}

.dottedLineGrid {
  align-self: center;
}

.metReserveText {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  margin-top: 16px;
}
.cardMainImageStyle {
  width: 302px;
  height: 300px;
}
.cardMainImageStyle img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.lineClamp {
  height: 59px !important;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.cardSubText {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 0;
}
.alignRight {
  float: right;
  padding-right: 12px;
}

.paymentStatusCol {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.paymentStatusVal {
  align-self: flex-start;
  padding-left: 2.8em;
}
