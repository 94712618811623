.roleInformationContainer {
  justify-content: center;
}

.roleInformation {
  width: 534px;
  justify-content: space-between;
  align-items: center;
  margin: 37px 400px 21px !important;
}

.roleInfoHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #000000;
}

.roleInformationCard {
  width: 534px;
  height: 431px;
  background: #f2f2f2;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.08);
}

.mobileNumber,
.roleColumn {
  padding: 0 0 0 61px;
}
