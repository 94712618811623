.auction_box_3 {
  width: 31%;
  overflow-y: hidden;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
}

aside.vedio_tab {
  background: #ededed;
  padding: 10px;
  margin-top: -6px;
}
.activeBidInsertRowItem .auction_live_sec {
  border: 2px solid #9ae2ff;
  border-radius: 10px;
  animation-name: blinking;
  animation-duration: 1s;
  animation-iteration-count: 100;
}
@keyframes blinking {
  50% {
    border-color: #ffcd5f;
  }
}

.vedio_tab .vedio_tab_1 {
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  min-width: fit-content;
}

.vedio_tab .vedio_tab_2 {
  margin-left: 10px;
}

.vedio_tab h4 {
  font-weight: 600;
  font-size: 12px;
  color: #000000;
  font-family: "Noto Serif", serif;
  margin-bottom: 0;
}

.vedio_tab .estimate_sec span {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: #000000;
  font-family: "Poppins", sans-serif;
}

.vedio_tab .estimate_sec .strong {
  font-size: 12px;
}

.vedio_tab_3 h6 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: #828282;
}

.vedio_overlay {
  background: rgb(0 0 0 / 30%);
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
}

.auction_box_vedio_sec:hover .vedio_overlay {
  visibility: visible;
  opacity: 1;
}

.scrn_big {
  position: absolute;
  right: 15px;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
}
#dataMessagesTextarea {
  display: none !important;
}
.auction_box_inner_list {
  position: relative;
  /* height: 100vh; */
}

.auction_box_inner_list ul {
  padding: 25px;
  position: relative;
  height: calc(100vh - 300px);
  overflow-y: hidden;
  overflow-x: hidden;
  padding-bottom: 70px;
  list-style: none;
}

.auction_box_inner_list ul:hover {
  overflow-y: auto;
}
.auction_box_right .btn_lots .lots_btn_same,
.live_action_fullscreen_mode .auction_box_inner_list .lots_btn_same {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  height: 56px;
  line-height: 56px;
  left: 0;
  bottom: 0;
  background: #333;
}

.live_action_fullscreen_mode {
  left: 0;
  top: 0;
  z-index: 9999;
  display: none;
}

.live_action_fullscreen_mode.active {
  display: flex;
}

.live_action_fullscreen_mode .auction_box_vedio_sec .live_action_video video {
  height: calc(100vh - 72px);
}

.live_action_fullscreen_mode .auction_box_vedio_sec {
  background: #ccc;
}

.live_action_fullscreen_mode aside.vedio_tab {
  padding: 15px 10px;
}

.live_action_fullscreen_mode .vedio_tab .vedio_tab_1 {
  font-size: 14px;
}

.live_action_fullscreen_mode .vedio_tab h4 {
  font-size: 15px;
}

.live_action_fullscreen_mode .vedio_tab .estimate_sec .strong {
  font-size: 13px;
}

.live_action_fullscreen_mode .vedio_tab_3 h6 {
  font-size: 12px;
}

.live_action_fullscreen_mode .auction_box_inner_list {
  width: 25%;
  height: 100vh;
}

.live_action_fullscreen_mode .auction_box_inner_list ul {
  height: calc(100vh - 56px);
  margin-bottom: 0;
}

.live_action_right_sidebar_icon {
  position: absolute;
  right: 30px;
  top: 10px;
  background: #fff;
  padding: 0px 5px 2px;
  border-radius: 6px;
}

.right_video_close_bar {
  right: -50px;
  top: 10px;
  border: 1px solid #000;
  border-radius: 50px;
  transition: all 0.5s ease-in-out;
}

.live_sec_cont {
  margin-left: 15px;
}

.live_sec_cont .live_sec_cont_type {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.04em;
  color: #000000;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0;
}

.live_sec_cont .live_sec_cont_amount {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.03em;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
}

.live_sec_icon_img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #f1f1f1;
  display: block;
  position: relative;
}
.auction_box_inner_list ul li {
  margin-bottom: 20px;
}

.live_sec_icon_img img {
  position: absolute;
  max-width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.auction_live_warning {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
  line-height: 21px;
  padding: 11px 15px;
  letter-spacing: 0.02em;
  border: 1px solid #fc0202;
  color: #000000;
  border-radius: 4px;
}
#messagesListParent div {
  margin: 6px 0px 6px 10px;
}
.loadingBoxCenter {
  text-align: center;
  margin-top: 10px;
}
#messagesListParent {
  min-height: 200px;
}
.auction_box_inner_list {
  overflow: scroll;
  /* overflow-x: scroll; */
  overflow-y: scroll;
  max-height: 250px;
  min-height: 250px;
  /* padding-bottom: 30px; */
}
.warningMsgList {
  margin-top: 30px;
  margin-bottom: 30px;
}
.displayNone {
  display: none;
}
.warningMsgList p {
  display: inline;
  font-size: 12px;
  cursor: pointer;
  padding: 5px;
  border-radius: 50px;
  margin-left: 15px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.warningMessgeTextsent {
  border: 1px solid red;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
}

@media (max-width: 1440px) {
  .auction_live_warning {
    font-size: 12px;
  }
}
.live_action_fullscreen_mode.active {
  display: flex;
}

.live_action_fullscreen_mode .auction_box_vedio_sec .live_action_video video {
  height: calc(100vh - 72px);
}

.live_action_fullscreen_mode .auction_box_vedio_sec {
  background: #ccc;
}

.live_action_fullscreen_mode aside.vedio_tab {
  padding: 15px 10px;
}

.live_action_fullscreen_mode .vedio_tab .vedio_tab_1 {
  font-size: 14px;
}

.live_action_fullscreen_mode .vedio_tab h4 {
  font-size: 15px;
}

.live_action_fullscreen_mode .vedio_tab .estimate_sec .strong {
  font-size: 13px;
}

.live_action_fullscreen_mode .vedio_tab_3 h6 {
  font-size: 12px;
}

.live_action_fullscreen_mode .auction_box_inner_list {
  width: 25%;
  height: 100vh;
}

.live_action_fullscreen_mode .auction_box_inner_list ul {
  height: calc(100vh - 56px);
  margin-bottom: 0;
}

.live_action_right_sidebar_icon {
  position: absolute;
  right: 30px;
  top: 10px;
  background: #fff;
  padding: 0px 5px 2px;
  border-radius: 6px;
}

.right_video_close_bar {
  right: -50px;
  top: 10px;
  border: 1px solid #000;
  border-radius: 50px;
  transition: all 0.5s ease-in-out;
}

.liveBidButton {
  margin-left: 30px;
  width: 150px;
  height: 56px;
  background: #000000;
  border: 1px solid #000000;
  border-radius: 3px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
}

.biddingBottomBlock {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 5px 10px 7px 17px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.biddingInputDiv {
  display: block;
  justify-content: space-between;
  align-items: center;
}

.enterBiddingTitle {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #656565;
}

.enterBidding {
  width: 226px;
  height: 56px;
  border: 1px solid rgba(51, 51, 51, 0.4);
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 5px;
  color: #000000 !important;
}

.enterBidding:hover,
.enterBidding:focus,
.enterBidding:active {
  border: 1px solid rgba(51, 51, 51, 0.4) !important;
}

.liveBidButton:hover,
.liveBidButton:active,
.liveBidButton:focus {
  color: #ffffff !important;
}

.CountingWarnStyles {
  width: 174px;
  height: 34px;
  border: 1px solid #c9c9c9;
  box-sizing: border-box;
  border-radius: 180px;
  padding: 4px 12px;
}

.lotCloseWarning {
  width: 246px;
  margin-left: 10px;
}
