.transactionDetailsHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 25px;
  color: #000000;
}

.transactionDetailsHeadingRow {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #000000;
}

.transactionDetailsTable {
  background: #feffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.transactionDetailsTable > thead > tr > th {
  width: 20%;
}

.transactionDetailsTable > tbody > tr {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
}

.transactionDetailsTable > tbody > tr > td:last-child {
  text-align: end;
}

.timelineTable {
  background: #feffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.timelineHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 25px;
  color: #000000;
}

.updateStatusBtn {
  width: 164px;
  height: 37px;
  background: #111111;
  border-radius: 4px;
  border: none;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #ffffff;
  float: right;
}

.updateStatusBtn:hover,
.updateStatusBtn:focus,
.updateStatusBtn:active {
  background: #000000 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.timelineText {
  position: relative;
  top: 5px;
}

.statusList {
  display: inline-block;
  list-style: none;
  padding: 35px 0px 36px 56px;
}

.statusList li:not(:last-child) {
  padding-bottom: 52px;
  border-left: dotted;
  margin-left: 20px;
}

.statusList li:last-child {
  margin-left: 20px;
}
.orderStatusIcons {
  margin-right: 48px;
  margin-left: -22px;
}

.orderStatusIcons::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: -50px;
  bottom: -50px;
  left: 50%;
  border-left: 2px dotted #ce9b3a;
}
