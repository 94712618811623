#showUserMessageDivID1 {
  width: 300px;
  border: none;
  border-radius: 3px;
  background-color: #000;
  color: #fff;
  min-height: 50px !important;
  display: none;
  flex-direction: row-reverse;
  text-align: center;
  align-items: center;
  position: fixed;
  bottom: 10px;
  left: 20px;
}

#showUserMessageDivID {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 5px 23px 5px;
  margin: 0px !important;
}

.closeIconStyles {
  position: absolute;
  top: 6px;
}
