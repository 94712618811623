.lotsContainer {
  margin-left: 50px;
  margin-right: 50px;
}

.lotsManagementRow {
  margin-top: 56px !important;
  margin-bottom: 49px !important;
  align-items: center;
}

.headingText {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 39.0625px;
  line-height: 63px;
  color: #000000;
}

.createCollectionBtn,
.createCollectionBtn:hover,
.createCollectionBtn:focus,
.createCollectionBtn:active {
  width: 210px;
  height: 50px;
  background: #000000 !important;
  border-radius: 4px;
  border: none;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.plusIcon {
  margin-right: 8px;
}

.cardGroup {
  margin-left: -15px !important;
  margin-right: -15px !important;
}

/* #collections-tabs>.row {
    margin-left : -15px !important;
    margin-right: -15px !important;
} */

.createBtnColumn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.viewMoreBtnRow {
  justify-content: center;
}

.viewMoreBtn {
  border: none;
  background: #000000;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  padding: 14px 32px;
  margin-top: 42px !important;
  margin-bottom: 84px !important;
}

.viewMoreBtn:focus,
.viewMoreBtn:active,
.viewMoreBtn:hover {
  background: #000000 !important;
  color: #ffffff !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.nav,
.nav-tabs {
  border-bottom: 1px solid #c4c4c4 !important;
}

.collectionsTabs {
  margin-bottom: 32px;
}

.collectionsTabs > .nav-item {
  margin-right: 64px;
}

.collectionsTabs > .nav-item > a {
  padding-bottom: 8px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #333333;
  opacity: 0.4;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: unset;
  border-bottom: 4px solid #000000;
  border-radius: 4px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #333333;
  opacity: unset;
}

.searchiconBox {
  float: right;
  padding-right: 53px;
}
.searchBox {
  float: right;
}
