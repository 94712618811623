.searchBox {
  width: 400px;
  height: 50px;
  background: #ffffff;
  box-shadow: 1px 1px 10px rgba(90, 90, 90, 0.2);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.iconBox {
  width: 90px;
  height: 50px;
  background: #010101;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchBoxTextField {
  width: 100%;
  border: none;
}

.searchBox > .form-control:focus {
  border: none !important;
  box-shadow: none !important;
}

.searchBoxTextField::-webkit-input-placeholder {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #959595;
}

.searchBoxTextField:hover,
.searchBoxTextField:focus,
.searchBoxTextField:active {
  border: none !important;
  box-shadow: none !important;
}
