/* .cardsColumn {
    padding-left : 0;
    padding-right: 24px;
} */

.card {
  /* width: 430px; */
  width: 100% !important;
  height: 442px;
  border: transparent;
  margin-bottom: 24px;
}

.collectionsCard {
  box-shadow: none !important;
}

.card-img {
  width: 430px;
  height: 300px;
}
.mainImageNew {
  /* max-width: 430px; */
  max-height: 300px;
  /* min-width: 430px; */
  min-height: 300px;
  object-fit: contain;
}
.card-title {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #333333;
}

.cardTitleHeight {
  height: 52px !important;
}

.card-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #333333;
  padding-top: 8px;
}

.card-body {
  padding: 0;
}

.browseAuctionBtn {
  width: 89px;
  height: 38px;
  border: 1px solid #333333;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* letter-spacing: 0.04em; */
  color: #333333;
  background-color: transparent;
  float: right;
  margin-top: 16px;
  padding: 0;
}

.browseAuctionBtn:hover {
  border: 1px solid #333333;
  background-color: #333333;
}

.browseAuctionBtn:active {
  border: 1px solid #333333 !important;
  background-color: #333333 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.browseAuctionBtn:focus {
  border: 1px solid #333333 !important;
  background-color: #333333 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.date {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 12px;
  line-height: 18px;
  color: #333333;
}

.lineStyles {
  margin-left: 8px;
  margin-right: 8px;
}
