.wsrContainer {
  /* height           : 518.01px; */
  /* background       : #F7F7F7; */
  /* padding       : 24px 21px 24px 0px; */
  display: flex;
  flex-direction: column;
  /* margin-top       : 38.99px !important; */
  /* padding-right       : 35px;
    padding-left        : 35.29px; */
}

.managewordManagerView {
  margin-top: 39px;
  background: #f7f7f7;
  margin-bottom: 30px;
}

.imageColumn {
  width: 616.18px;
  min-height: 473.14px;
  margin-top: -38px;
  background: linear-gradient(0deg, #ededed, #ededed),
    url("./../../assets/BigDaddy.jpg");
}

.imageColumnWord {
  width: 60%;
  float: left;
  height: 513px;
}

.imageColumn img {
  max-width: 100%;
  max-height: 100%;
  object-fit: fill;
}

.wordSectionImage {
}

.wsBannerTitle {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 41.7749px;
  line-height: 57px;
  color: #111111;
  padding-top: 40px;
}

.wordIndicatorsList {
  list-style-type: none;
  padding-left: 0px;
  padding-top: 16px;
  margin-left: -6px;
}

.wordBannerIndicators {
  border: 0.696248px solid #111111;
  width: 20.89px;
  height: 4.18px;
  margin: 4.18px;
}

.filledIndicators {
  width: 20.89px;
  height: 4.18px;
  background: #111111;
}

.wsRightContainer {
  padding-left: 29.2px;
  width: 40%;
  float: left;
  height: 518px;
}

.bannerIconsRow {
  margin-top: -14px !important;
}

.birSpacing {
  margin-right: -35px !important;
}

.bannerStoriesText {
  padding-left: 11.9px;
  padding-bottom: 5px;
  align-items: center;
  display: flex;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11.14px;
  line-height: 15px;
  color: #111111;
}

.bannerRightContent {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 18px;
  color: #111111;
}

.bannerDiscoverBtn {
  background: #111111;
  border: 0.696248px solid #111111;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16.71px;
  line-height: 25px;
  letter-spacing: 0.04em;
  color: #ffffff;
  margin-top: 32px;
}

.bannerDiscoverBtn:hover,
.bannerDiscoverBtn:focus,
.bannerDiscoverBtn:active {
  background: #111111 !important;
  border: 0.696248px solid #111111 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.bannerArrowsRow {
  margin-top: 50.29px !important;
  margin-left: -30px !important;
}

.leftArrBox,
.rightArrBox {
  width: 27.85px;
  height: 27.85px;
  border: 0.696248px solid rgba(0, 0, 0, 0.3);
  border-radius: 5.56999px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 11.4px;
}
