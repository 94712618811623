.carouselStyles {
  width: 100%;
  /* background: linear-gradient(0deg, #6C6C6C, #6C6C6C); */
  height: 550px;
}

.cardsCarouselStyles {
  width: 100%;
  /* background: linear-gradient(0deg, #6C6C6C, #6C6C6C); */
  height: 550px;
  margin-top: 110px;
}

.carouselStyles > a {
  /* display: none; */
}

.carouselStyles > .carousel-control-next,
.carouselStyles > .carousel-control-prev {
  top: 18%;
  width: 5%;
}

#cardsCarousel > .carousel-control-next,
#cardsCarousel > .carousel-control-prev {
  top: 32%;
  bottom: unset;
  width: 7%;
}

.carouselStyles > .carousel-control-prev {
  left: 14%;
}

.carouselStyles > .carousel-control-next {
  left: 17%;
}

.carousel-indicators > li {
  width: 62.5px;
  height: 6px;
  margin-right: 24px;
}

.carousel-item {
  width: 100%;
}

.carouselImages {
  width: 100%;
  height: 550px;
}

.carousel-caption {
  right: unset;
  height: 0;
  top: 40%;
  bottom: 0px;
}

.carouselIndicatorColumn {
  background: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.carouselNumber > .indicatorNumber {
  display: flex;
  width: 30px;
  border: 1px solid #fff;
  padding: 2px;
  margin: 30px;
  border-radius: 50%;
  justify-content: center;
  cursor: pointer;
}

#imageCarousel > .carousel-indicators {
  display: none;
}

/* Cards Carousel */

.cardsCarouselRow {
  margin-top: 100px;
}
