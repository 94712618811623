.live_auction_page .auction_box_1 {
  width: 26%;
}

.live_auction_page .auction_box_1 h2 {
  padding: 20px;
}

.live_auction_page .auction_box_1 h2 span {
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  color: #111111;
  font-family: "Noto Serif", serif;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.liveDotIcon {
  height: 12px;
  width: 12px;
  background-color: #008000;
  border-radius: 50%;
  display: inline-block;
}
.live_auction_page .auction_box_1 h2 img {
  top: 10px;
  right: 10px;
}

.live_auction_page .auction_box_1 .auction_left_flex_box {
  font-family: "Poppins", sans-serif;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding: 20px;
}

.live_auction_page .auction_box_1 .auction_left_flex_box .auction_left_img img {
  min-width: 60px;
  min-height: 60px;
  width: 60px;
  height: 60px;
  object-fit: contain;
  border-radius: 4px 4px 0px 0px;
  /* background: #c4c4c4; */
}

.live_auction_page .auction_box_1 .auction_left_flex_box:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.3);
}

.live_auction_page .auction_box_1 .auction_left_flex_box .auction_left_details {
  padding-left: 20px;
}

.live_auction_page
  .auction_box_1
  .auction_left_flex_box
  .auction_left_details
  h6 {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #111111;
  display: inline-block;
  margin-bottom: 0;
  position: relative;
}

.live_auction_page
  .auction_box_1
  .auction_left_flex_box
  .auction_left_details
  h6.online::before {
  content: "";
  position: absolute;
  right: -15px;
  top: 4px;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #41b64a;
}

.live_auction_page
  .auction_box_1
  .auction_left_flex_box
  .auction_left_details
  h4 {
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin: 5px 0;
  font-family: "Noto Serif", serif;
}

.live_auction_page
  .auction_box_1
  .auction_left_flex_box
  .auction_left_details
  h5 {
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.5);
}

.live_auction_page
  .auction_box_1
  .auction_left_flex_box
  .auction_left_details
  h3 {
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.6);
}

.live_auction_page .auction_box_1 .auction_left_list {
  height: calc(100vh - 100px);
  overflow-y: hidden;
  padding-bottom: 60px;
  overflow-x: hidden;
}

.live_auction_page .auction_box_1 .auction_left_list:hover {
  overflow-y: auto;
}

.live_action_left_sidebar_icon {
  position: absolute;
  left: 30px;
  top: 10px;
  background: #fff;
  border: 1px solid #000;
  padding: 0px 5px 2px;
  border-radius: 6px;
}

@media (max-width: 1500px) {
  .live_auction_page .auction_box_1 h2 span {
    -webkit-line-clamp: 3;
  }
}
