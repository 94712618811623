.printIcon {
  margin-right: 25px;
  cursor: pointer;
}

.downloadIcon {
  margin-right: 25px;
  margin-left: 25px;
  cursor: pointer;
}

.browseLotIcons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -14px !important;
  margin-bottom: 33px !important;
}
.Usertable-info .Paginationbtns {
  padding-top: 15px;
}
