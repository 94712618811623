/* .tags-input {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 48px;
    width: 480px;
    padding: 0 8px;
    border: 1px solid #d6d8da;
    border-radius: 6px;
} */

.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 589px;
  border: 1px solid rgba(51, 51, 51, 0.3);
  border-radius: 3px;
}

.tags-input:focus-within {
  border: 1px solid #333333 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.tags-input input {
  flex: 1;
  border: none;
  height: 40px;
  font-size: 16px;
  padding-left: 10px;
}

.tags-input input:focus {
  outline: transparent;
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 5px 0 0 0;
}

.tag {
  width: auto;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  border-radius: 6px;
  background: #ebebeb;
  border-radius: 30px;
  padding: 5px 10px 4px 15px;
  margin-left: 7px;
}

.tag .tag-title {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  text-transform: capitalize;
}

.tag > .closeIcon {
  margin-left: 10px;
}

/* @media screen and (max-width: 567px) {
    .tags-input {
        width: calc(100vw - 32px);
   }
} */
