.reviewBody {
  padding-left: 0;
}

.AngleUpIcon {
  float: right;
}

.reviewSubHeading {
  width: 100%;
  margin-top: 40px !important;
  margin-left: 33px !important;
  margin-right: 33px !important;
}

.headingLine {
  width: 100%;
  opacity: 0.3;
  border-bottom: 1px solid #333333;
}

.editColumn {
  display: flex;
  justify-content: flex-end;
}

.detailsRow {
  justify-content: center;
  width: 100%;
}

.namesColumn {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: right;
  color: #000000;
  padding-bottom: 18px;
}

.valuesColumn {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
  display: flex;
  align-items: center;
  padding-bottom: 18px;
}

.mediaContainer {
  margin-top: 24px !important;
  margin-left: 115px !important;
}

.mediaContainer > img:first-child {
  margin-left: 0 !important;
}

.mediaContainerHeading {
  width: 100%;
}

.reviewImage {
  max-width: 104px;
  max-height: 95px;
  margin: 0 12.5px;
}

.provinanceDetails {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  color: #000000;
}

.headingColumn {
  width: 100%;
  text-align: right;
}

.detailsHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  width: 100%;
}

.previewBtnRow {
  width: 100%;
  justify-content: flex-end;
}

.previewBtn {
  width: 143px;
  height: 50px;
  background: #000000;
  border: none;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.previewBtn:focus,
.previewBtn:hover {
  background: #000000 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.bottomSpace {
  padding-bottom: 18px;
}
.editColumn svg {
  cursor: pointer;
}
.subHeadingText {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
}
.provinanceDetailsSection > div {
  display: block;
  margin-top: 10px;
}
.provinanceDetailsSection {
  padding-left: 35px;
  display: block;
}

.editorReviewSpacing {
  padding: 0px 60px;
}

.editorReviewSpacing.noEditText
  .public-DraftEditor-content[contenteditable="true"] {
  -webkit-user-modify: unset !important;
}
.videosIMageReview img {
  width: 104px;
  height: 95px;
}
.videosIMageReview svg {
  height: 82px;
  margin-left: -94px;
  border: 3px solid #000;
  border-radius: 50%;
  padding: 10px;
}
