.bannerContainer {
  width: 100%;
  min-height: 365px;
  height: 100%;
  background: #ececec;
  padding: 24px 50px 16px;
  margin-bottom: 31px;
}

.bannerRow {
  min-height: 365px;
  height: 100%;
}

.columnStyles {
  padding: 0;
}

.chevronLeft {
  cursor: pointer;
  margin-bottom: 5px;
}

/* .btnColumn {
    width         : 100%;
    height        : 100%;
    flex-direction: row;
} */

.btnTopRow {
  justify-content: space-between;
}

.btnBottomRow {
  position: absolute;
  bottom: 0px;
  width: 100%;
  justify-content: flex-end;
}

.bannerTitle {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  line-height: 63px;
  color: #333333;
}

.date {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #333333;
}

.time,
.onlineAuction {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #333333;
}

.lineStyles {
  margin-left: 8px;
  margin-right: 8px;
}

.editBtn {
  width: 126px;
  height: 50px;
  border: 1px solid #000000;
  background-color: #ffffff;
  border-radius: 4px;
  margin-right: 24px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.editBtn:hover,
.editBtn:focus {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
  background: #ffffff !important;
  border: 1px solid #000000 !important;
  color: #000000 !important;
}

.publishBtn {
  width: 155px;
  height: 50px;
  background: #000000;
  border: none;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.publishBtn:hover,
.publishBtn:focus {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
  background: #000000 !important;
  color: #ffffff !important;
}

.previewAndPublishBtn {
  width: 235px;
  height: 50px;
  background: #000000;
  border: none;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.previewAndPublishBtn:hover,
.previewAndPublishBtn:focus {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
  background: #000000 !important;
  color: #ffffff !important;
  cursor: pointer;
}

.createLotBtn,
.createLotBtn:hover,
.createLotBtn:focus,
.createLotBtn:active {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 148px;
  height: 50px;
  background: #000000 !important;
  border-radius: 4px;
  border: none;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
}

.bannerHeadingText {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 5px;
  color: #333333;
}
