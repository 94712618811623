.angleUpIcon {
  float: right;
}

.formHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  padding-left: 10px;
  padding-right: 10px;
}
.image-error {
  color: red;
}
.form-label {
  margin-top: 30px;
  width: 100%;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
}

.requiredField::after {
  content: "*";
  color: red !important;
}

.form-control {
  width: 589px;
}

.form-control:focus {
  border: 1px solid #333333 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.input[type="radio"]:after {
  background-color: red;
}

.collectionsDetailsBody {
  padding-left: 33px !important;
  padding-right: 33px !important;
}

.imageContainer {
  background: #dcd8d8;
  border: 1px dashed #4e4e4e;
  border-radius: 9px;
  height: 256px;
  margin: 72px 66px 43px !important;
  align-items: center;
}

.uploadImageBtn {
  border: none;
  width: 194px;
  height: 38px;
  background: #000000;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  margin: 174px auto 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploadImageBtn:focus,
.uploadImageBtn:hover {
  background-color: #333333 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.previewImage {
  width: 100%;
  height: 100%;
}

.previewImageCollection {
  max-height: 190px;
  max-width: 250px;
  min-height: 100px;
}

.imageRow,
.uploadBtnRow {
  width: 100%;
  justify-content: center;
}

.collectionsRadioBtn {
  padding-top: 0 !important;
  margin-top: 4px !important;
}

.radioButtonsLabel {
  display: inline-block;
  margin-left: 50px;
}

.rightRadioBtn {
  padding-left: 25.5px;
}

.form-check-Buttons_radio span {
  cursor: pointer;
}

.start_date_select {
  width: 170px !important;
}

.selectDateRow,
.selectTimeRow {
  width: 189px;
  float: left;
}

.selectTimeRow input {
  height: 40px;
  font-size: 19px;
  width: 158px;
}

.COllectionStartTIme,
.COllectionEndTIme {
  width: 48%;
  float: left;
}

.selectTimeRow a i {
  display: none;
}

.form-check-Buttons_radio {
  margin: 0px;
  margin-top: -7px;
  margin-bottom: -15px;
}

.rc-time-picker-panel-select > ul {
  display: block;
}

.rc-time-picker-clear {
  display: none;
}

.ActionDateErrorsData {
  margin-top: 5px !important;
  color: red;
}

.deleteImgIcon {
  float: right;
  display: flex;
}

.labelStyles {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .collectionsRadioBtn input[type=radio]:before {
    content: " ";
    background-color: red !important;
    display: inline-block;
    visibility: visible;
   }

   .form-check-custom-variant {
    background: orange;
  } */
