.orderDetailsContainer > .table-bordered td,
.orderDetailsContainer > .table-bordered th {
  background: #feffff;
  border: none;
  border-bottom: 1px solid #e2e2e2;
  /* box-shadow: 0px 2px 4px rgb(0 0 0 / 19%); */
  border-radius: 2px;
}

.orderDetailsHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 25px;
  color: #000000;
}

.orderDetailsHeadingRow {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #000000;
}

.orderDetailsTable {
  background: #feffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.orderDetailsTable.top {
  margin-top: -17px;
  display: flex;
  justify-content: flex-end;
  border-radius: unset;
}

.orderDetailsTable > thead > tr > th {
  width: 20%;
}

.orderDetailsTable > tbody > tr > td {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
}

.orderDownloadInvoice {
  width: 194px;
  height: 37px;
  background: #111111;
  border: none;
  border-radius: 4px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #ffffff;
  float: right;
}

.orderDownloadInvoice:hover,
.orderDownloadInvoice:active,
.orderDownloadInvoice:focus,
.paymentReminderBtn:hover,
.paymentReminderBtn:active,
.paymentReminderBtn:focus {
  background: #000000 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.paymentReminderBtn {
  width: 219px;
  height: 38px;
  background: #111111;
  border: none;
  border-radius: 4px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #ffffff;
  float: right;
}
