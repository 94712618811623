.hslContainer {
  background: #ededed;
  padding: 24px 21px 24px 23px;
}

.hslHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #333333;
  margin-bottom: 0px;
  cursor: pointer;
}
.hideImageforHeight {
  display: none;
}
.selectOption {
  max-width: 100%;
}
.textCenterBox {
  justify-content: center;
  margin: 10px 10px !important;
}
.hslSideHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  color: #000000;
}
.previewImageCollectionHero {
  max-height: 133px;
}
.hslImageContainer {
  height: 163px;
  background: #dcd8d8;
  border: 1px dashed #4e4e4e;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-content: center;
}
.errorImageFIleHero {
  color: red;
}
.spinner-borderWord {
  margin-top: 20px;
  text-align: center;
}
.hslUploadBtn {
  width: 110.11px;
  height: 27.7px;
  background: #000000;
  border: none;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding: 5px;
}

.hslUploadBtn:hover,
.hslUploadBtn:focus,
.hslUploadBtn:active,
.savePublishBtn:hover,
.savePublishBtn:focus,
.savePublishBtn:active {
  background: #000000 !important;
  color: #ffffff !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.selectOption {
  border: 1px solid rgba(51, 51, 51, 0.3);
  color: #000000 !important;
  border-radius: 2px;
}

.selectOption > option {
  color: #000000;
}

.savePublishBtn {
  width: 324px;
  height: 50px;
  background: #000000;
  border: none;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  margin-top: 40px;
}

.hslCancelBtn {
  width: 324px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin-top: 19px;
}

.hslCancelBtn:hover,
.hslCancelBtn:focus,
.hslCancelBtn:active {
  background: #ffffff !important;
  color: #000000 !important;
  border: 1px solid #000000 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}
