.tabsContainer {
  height: 66px;
  background: #ffffff;
  border: 2px solid #f1f1f1;
  display: flex;
  align-items: center;
}

.tabsList {
  display: flex;
  height: 100%;
  margin-left: 62px;
}

.nav {
  border: none;
}

.nav-tabs .nav-link {
  border: none;
  padding: 0;
}

.tabsList > title {
  display: none;
}

.tabsList > a {
  display: flex;
  height: 100%;
  align-items: center;
  margin: 0px 20px;
}

#main-tab-tab-dashboardSelected,
#main-tab-tab-collectionsSelected,
#main-tab-tab-auctionManagementSelected,
#main-tab-tab-contentManagementSelected,
#main-tab-tab-reportsSelected,
#main-tab-tab-rolesSelected,
#main-tab-tab-siteManagerSelected,
#main-tab-tab-orderManagementSelected,
#main-tab-tab-userSelected {
  background-color: #000000;
  margin: 0px 50px;
}

.tabIcon {
  margin-right: 8px;
}

.tabIconText {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: inline-block;
  color: #ffffff;
}

/* .tabsList>a:active,
.tabsList>a:focus {
    background: #131313 !important;
}

.tabsList>a:active>.tabIconText,
.tabsList>a:focus>.tabIconText {
    font-family: Poppins;
    font-style : normal;
    font-weight: 500;
    font-size  : 14px;
    line-height: 21px;
    display    : inline-block;
    color: #FFFFFF;
} */
