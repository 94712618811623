.AngleUpIcon {
  float: right;
}

.AccordionEditBtn {
  width: 110px;
  height: 50px;
  border: 1px solid #000000;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  float: right;
}

.AccordionEditBtn:active,
.AccordionEditBtn:hover,
.AccordionEditBtn:focus {
  background-color: #ffffff !important;
  color: #000000 !important;
  border: 1px solid #000000 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.ocliBody {
  padding-left: 33px !important;
  padding-right: 33px !important;
}

.mediumFields {
  width: 280px;
  background-color: #ffffff !important;
}

.formSideHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  width: 100%;
  padding-top: 20px;
  margin: 0;
}

.extraTopPadding {
  padding-top: 69px;
}

.saveContinue {
  width: 214px;
  height: 50px;
  background: #000000;
  border: none;
  border-radius: 4px;
  margin-top: 48px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.saveContinue:focus,
.saveContinue:hover {
  background: #000000 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.checkboxText {
  padding-left: 34.5px;
}

.checkboxText > label {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #646363;
}
.locationTextField {
}
.googleMapLocationBase {
  margin-top: 30px;
}
