.filterLotsContainer {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.tabsRow {
  margin: 39px 0 100px 0;
}

.tabsColumn {
  padding: 0;
}

.nav {
  border: none;
}

.nav-tabs .nav-link {
  border: none;
  padding: 0;
}

.nav > a {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #333333;
  opacity: 0.4;
  margin-right: 132px;
  margin-top: 32px;
  padding-bottom: 9px !important;
}

.nav > a:focus {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  opacity: unset;
  color: #333333;
  border-bottom: 4px solid #000000 !important;
  border-radius: 2px !important;
}

.gridViewIcon {
  margin-right: 25px;
  cursor: pointer;
}

.listViewIcon {
  margin-right: 20px;
  cursor: pointer;
}

.viewRow {
  width: 100%;
  justify-content: flex-end;
}

.tabsHeadingLine {
  border: 1px solid #c4c4c4;
  width: 100%;
  opacity: 0.3;
}

.sortBy {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #212121;
}

.filterLotsRow {
  width: 100%;
  margin-top: 12px !important;
}

.filterByColumn {
  padding: 0;
}

.cardMatrix {
  padding: 0;
  margin-bottom: 48px;
}

.card-deck {
  margin: 0;
}

.card-deck > .card {
  margin-left: 24px;
  margin-right: 24px;
}

.card-columns > .card {
  margin-bottom: 48px;
  margin-left: 24px;
  margin-right: 24px;
}

.sortByBlock {
  width: 279px;
  height: 48px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-bottom: 19px;
}

.sortByText {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  justify-content: center;
  color: #212121;
  opacity: 0.4;
  padding-left: 16px;
}

.sortBySelect {
  border: none;
  width: 196px !important;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #333333;
  padding-left: 2px;
}

.sortBySelect:focus,
.sortBySelect:hover,
.sortBySelect:active {
  border: none !important;
  box-shadow: unset !important;
}

.auctionNameValues {
  flex-direction: column;
  margin-bottom: 64px !important;
}

.auctionDetailsLeft {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: rgba(51, 51, 51, 0.7);
}

.auctionDetailSpacing {
  flex-direction: column;
  padding-left: 44px;
}

.adDate {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #333333;
  margin-left: 58px;
}

.adTime,
.adOnlineAuction {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  color: #333333;
}

.furtherInfoText {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: rgba(51, 51, 51, 0.7);
}

.forAssistText {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #333333;
}

.forAssistContent {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #333333;
}

.adAccordion {
  width: 80%;
  margin-left: 23px;
  margin-top: 64px;
}

.adCardBlock {
  margin-top: 35px !important;
  flex-direction: column;
  padding-left: 20px;
}

.adCardHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #333333;
}

.adCardContent {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: rgba(51, 51, 51, 0.7);
}

.adTitle {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #333333;
  margin-left: 49px;
}

.lotsNumberSpacing {
  margin-left: 34px;
}
.searchBoxLotsPage {
  display: flex;
  justify-content: space-between;
}
.searchBoxLotsPage .searchBox {
  /* margin-left: 100px; */
}

.loadMoreRow {
  justify-content: center;
}

.loadMoreBtn {
  width: 129px;
  height: 44px;
  background: #333333;
  border-radius: 5px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #ffffff;
}

.loadMoreBtn:hover,
.loadMoreBtn:active,
.loadMoreBtn:focus {
  background: #333333;
  color: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.scrollContent {
  max-height: 600px;
  overflow-y: scroll;
}

.scrollContent::-webkit-scrollbar {
  width: 3px;
  margin-top: 10px;
  border-radius: 3px;
  background-color: #aaa;
  overflow: hidden;
  overflow-y: scroll;
}

.scrollContent::-webkit-scrollbar-track {
  background: #ffffff;
}

.scrollContent::-webkit-scrollbar-thumb {
  background: #979797;
}
.scrollContent .public-DraftEditor-content[contenteditable="true"] {
  -webkit-user-modify: unset !important;
}
