.hsrContainer {
  /* height    : 414.5px; */
  width: 1000px;
  /* background: #EDEDED; */
  padding: 0px 21px 24px 23px;
  display: flex;
  flex-direction: column;
}

.bannerIconsRow {
  justify-content: flex-end;
  margin-right: 35px !important;
}
.bannerIconsRow svg {
  cursor: pointer;
}

.bannerContentRow {
  padding-left: 67px;
  padding-top: 37.22px;
}

.bannerTextContent {
  flex-direction: column;
  padding-top: 31px !important;
}

.bannerCategoryText {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: bold;
  font-size: 9.67173px;
  line-height: 13px;
  margin-bottom: 0px;
}

.bannerTitleText {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: bold;
  font-size: 41.4503px;
  line-height: 56px;
  margin-bottom: 6.18px !important;
}

.bannerSubText {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11.0534px;
  line-height: 15px;
  margin-bottom: 11.25px !important;
}

.leftArrowBox,
.rightArrowBox {
  width: 23.49px;
  height: 23.49px;
  background: #000000;
  border: 0.533829px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  border-radius: 0.690838px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.boxSpacing {
  margin-right: 5.5px;
}

.BannerEditIcon {
  margin-right: 25px;
}

.lineContainer {
  width: 20px;
  display: flex;
  margin-right: 31.83px !important;
}

.bannerCountContainer {
  display: flex;
  justify-content: flex-end;
}

.bannerNumberLine {
  /* width: 261.14px; */
  list-style-type: none;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  height: 220px;
  border: none;
  border-right: 2.07251px solid rgba(255, 255, 255, 0.3);
  /* padding-right: 11.14px !important; */
  /* border: 2.07251px solid rgba(255, 255, 255, 0.3); */
  /* border: 1px solid Red; */
}

.numberActiveIndicator {
  padding-right: 11.44px !important;
  border-right: 2.3px solid #ffffff;
  margin-right: -2px;
  height: 60px;
  padding-top: 11px;
}

.hsrIcon {
  cursor: pointer;
}

.bannerNumberCount {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 27.6335px;
  line-height: 38px;
  /* display: flex;
    align-items: center;
    text-align: center; */

  color: #ffffff;
}
.imageSectionView {
  width: 100%;
  height: 415px;
}

.imageSectionView img {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  object-fit: fill;
}
.manageSiteManagerView {
  /* height: 550px; */
  height: 415px;
  margin-bottom: 32px;
}
.BottomSectionView {
  margin-top: -375px;
}
.finalTextcolorName {
  /* background-color: #fff; */
  color: #ffffff;
  padding: 5px;
}
