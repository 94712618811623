.customerInformationHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 25px;
  color: #000000;
}

.customerInformationHeadingRow {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #000000;
}

.customerInformationTable {
  background: #feffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.customerInformationTable > thead > tr > th {
  width: 20%;
}

.customerInformationTable > tbody > tr {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
}

.customerInformationTable > tbody > tr > td:last-child {
  text-align: end;
}

.CustomerInformationText {
  width: 211px;
  height: 25px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 25px;
  color: #000000;
  text-align: left;
  vertical-align: top;
  margin-bottom: 8px;
  margin-top: 16px;
}

.InputsForm {
  width: 474px !important;
  height: 40px !important;
}

.FormSelectDiv {
  border: 1px solid #ced4da;
  border-radius: 4px !important;
}

.InputsFormSelect {
  width: 452px;
  height: 37px;
  font-family: Noto serif;
  font-style: normal;
  font-size: 16px;
  line-height: 25.89px;
  text-align: left;
  vertical-align: top;
  border: none !important;
}
.InputsFormSelect:focus,
.InputsFormSelect:hover,
.InputsFormSelect:active {
  border: none !important;
  box-shadow: unset !important;
}

.courierTestOption {
  font-family: Noto serif;
  font-style: normal;
  font-size: 14px;
  line-height: 23px;
  text-align: left;
  vertical-align: top;
  color: #000000;
  height: 38px;
  width: 474px;
}

.ContentDiv > .form-label {
  margin-top: 24px;
}

.LogisticInformationText {
  width: 193px;
  height: 25px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 25px;
  color: #000000;
  text-align: left;
  vertical-align: top;
}

.TitleSelectDiv {
  width: 99px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.InputsSelect {
  width: 84px;
  height: 36px;
  font-family: Noto serif;
  font-style: normal;
  font-size: 16px;
  line-height: 25.89px;
  text-align: left;
  vertical-align: top;
  border: none;
  border: #ffffff !important;
}

.InputsSelect:focus,
.InputsSelect:hover,
.InputsSelect:active {
  border: none !important;
  box-shadow: unset !important;
  font-family: Noto Serif;
  font-weight: 600;
  font-size: 19px;
  line-height: 25px;
  color: #000000;
}

.logisticInformationHeadingRow {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #000000;
}

.logisticInformationTable {
  background: #feffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.logisticInformationTable > thead > tr > th {
  width: 20%;
}

.logisticInformationTable > tbody > tr {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
}

.logisticInformationTable > tbody > tr > td:last-child {
  text-align: end;
}

.tableEditIcon {
  float: right;
  cursor: pointer;
}
