.createArticleContainer {
  margin-top: 52px;
  margin-left: 50px;
}
.articleHeadingRow {
  margin: 0;
  display: flex;
  align-items: center;
  margin-bottom: 32px !important;
}

.articleHeadingRow > .contentMgmtHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #333333;
  margin-left: 5px;
  cursor: pointer;
}

.createArticleContainer > .createArticle {
  width: 860px !important;
  margin: auto;
}

.createArticleHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 45px;
  color: #000000;
  border-bottom: 1px solid #b0b0b0;
}

.createArticleBody {
  padding-left: 23px !important;
  padding-right: 30px !important;
  background: #f9f9f9;
  padding-bottom: 50px !important;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.08);
}

.createArticleBody input,
.createArticleBody textarea {
  background: #f9f9f9;
}

.excerpt {
  width: 100%;
}

.excerptSubText {
  font-family: Poppins;
  font-style: normal;
  font-family: Noto Serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
}

.excerptField {
  width: 100%;
  padding: 16px 33px 56px 33px;
}

.imageContainer {
  background: #dcd8d8;
  border: 1px dashed #4e4e4e;
  border-radius: 9px;
  height: 256px;
  margin: 72px 66px 43px !important;
  align-items: center;
}

.imageContainerArticle img {
  max-height: 252px;
  max-width: 670px;
  object-fit: cover;
}

.imageContainerArticle {
  border: 1px dashed red !important;
}

.imageRow,
.uploadBtnRow {
  width: 100%;
  justify-content: center;
}

.imageRow > .uploadBtnRow {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.uploadImageBtn {
  border: none;
  width: 194px;
  height: 38px;
  background: #000000;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  margin: 0;
}

.uploadImageBtn:focus,
.uploadImageBtn:hover {
  background-color: #333333 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.previewImage {
  width: 100%;
  height: 100%;
}

.btnsRow {
  margin-top: 80px !important;
  margin-bottom: 114px !important;
  justify-content: space-between;
}

.cancelBtn {
  width: 138px;
  height: 50px;
  border: 1px solid #000000;
  background-color: #ffffff !important;
  color: #000000;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.cancelBtn:hover,
.cancelBtn:focus,
.cancelBtn:active {
  color: #000000 !important;
  border: 1px solid #000000 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.savePublish {
  width: 199px;
  height: 50px;
  background: #000000;
  border: none;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.savePublish:hover,
.savePublish:focus,
.savePublish:active {
  border: 1px solid #000000 !important;
  background: #000000 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.createArticleTextpage {
  width: 580px !important;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.ArticlePreviewBtn {
  margin-left: 47%;
}

.tagsSubtext {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 26px;
  margin-left: 10px;
}

.articleImageLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
