.AngleUpIcon {
  float: right;
}

.provenanceBody {
  padding-left: 33px !important;
  padding-right: 33px !important;
}

.ownerNameField {
  width: 187px;
  height: 40px;
  border: 1px solid #8a8a8a;
  border-radius: 2px;
  margin-right: 25px;
  margin-top: 12px;
}

.ownerCloseButton {
  display: flex;
  align-items: center;
}

.addMoreOwnersBtn {
  width: 185px;
  height: 40px;
  background: #000000;
  border-radius: 2px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  color: #ffffff;
  border: none;
  margin-top: 12px;
}

.plusIcon {
  margin-right: 8px;
}

.addMoreOwnersBtn:focus,
.addMoreOwnersBtn:hover {
  background: #000000 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.saveContinue {
  width: 214px;
  height: 50px;
  background: #000000;
  border: none;
  border-radius: 4px;
  margin-top: 48px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.saveContinue:focus,
.saveContinue:hover {
  background: #000000 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}
