.sellConatctDiv {
  height: 32px;
  background: #1b1b1b;
  border-radius: 0px;
}

.appLogo {
  margin-left: 50px;
  max-height: 35px;
}

.resetPasswordMainDiv {
  width: 656px;
  margin: auto;
}

.newPasswordHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 54px;
  text-align: center;
  color: #111111;
  margin: auto;
}

.headingParagraphDiv {
  padding: 24px 0px 0px 31px;
  width: 630px;
}
.headingParagraph {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #111111;
}

.submitBtn {
  width: 225px;
  height: 49px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 80px;
  background: #111111;
  border: 1px solid #111111;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 40px;
  color: #ffffff;
  box-shadow: unset !important;
  margin-bottom: 40px;
}

.submitBtn:hover {
  background: #111111 !important;
}

.passwordErrorError {
  color: red;
}
