.viewLotContainer {
  margin: 40px 50px 80px;
}
.errorBiddingUser {
  color: red;
}
.mainImageColumn {
  margin-left: 25px;
  margin-right: 25px;
}

.viewLotTitleRow {
  padding-bottom: 34px;
}
.viewLotTitleRow span {
  cursor: pointer;
}
.lotNumberRow {
  margin-bottom: 8px !important;
}

.imagesWrapper {
  margin-bottom: 56px !important;
}

.sideImagesColumn {
  height: 505px;
  overflow: hidden;
}
.sideImagesColumn img {
  cursor: pointer;
}
.sideImagesColumn:hover {
  overflow-y: scroll;
}

.sideImagesColumn::-webkit-scrollbar {
  width: 3px;
  margin-top: 10px;
  border-radius: 3px;
  background-color: #aaa;
  overflow: hidden;
  overflow-y: scroll;
  /* min-height      : 100px; */
}

.sideImagesColumn::-webkit-scrollbar-track {
  background: #ffffff;
}

.sideImagesColumn::-webkit-scrollbar-thumb {
  background: #979797;
}

.viewLotTitle {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  color: #000000;
}
.spinner-borderWord1 {
  min-height: 500px;
}
.viewLotLabel {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 0;
}

.viewLotValue {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.lotViewBtnRow {
  margin-top: 32px !important;
}

.viewLotCancelBtn {
  width: 149px !important;
  height: 56px;
  border: 1px solid #000000;
  background: #ffffff;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #000000;
  margin-right: 16px;
}

.viewLotCancelBtn:hover,
.viewLotCancelBtn:focus,
.viewLotCancelBtn:active {
  border: 1px solid #000000 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
  background: #ffffff !important;
  color: #000000 !important;
}

.viewLotEditBtn:hover,
.viewLotEditBtn:focus,
.viewLotEditBtn:active {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
  background: #000000 !important;
}

.viewLotEditBtn {
  width: 149px !important;
  height: 56px;
  background: #000000;
  border: none;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
}

.textBottomStyles {
  margin-bottom: 24px !important;
}

.wrapText {
  overflow-wrap: anywhere;
}

.lotButton {
  width: 59px;
  height: 33px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.04em;
  color: #333333;
  padding: 0;
}

.lotButton:focus,
.lotButton:hover,
.lotButton:active {
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  background: #ffffff !important;
  color: #333333 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.sideImages {
  width: 203px;
  height: 149px;
  margin-bottom: 17px;
  object-fit: contain;
}

.mainImage {
  object-fit: contain;
  width: 647px;
  height: 488px;
}

.lotRightContainer {
  margin-left: 25px;
}

.viewLotTabs > a {
  margin-right: 40px;
}

.viewLotTabs > a:focus {
  border-bottom: 2px solid #cccccc !important;
}

.contentStyles {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #333333;
  padding-top: 24px;
}

.asWinnerBtn,
.asWinnerBtn:hover,
.asWinnerBtn:focus,
.asWinnerBtn:active {
  width: 187px;
  height: 38px;
  background: #333333 !important;
  border: none;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
}

.addNewBidColumn {
  padding: 9px 0 60px 40px !important;
}

.addNewBidText {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #333333;
  margin-bottom: 18px;
}

.enterBiddingId {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #656565;
}

.biddingIdField {
  width: 270px;
  height: 50px;
  margin-right: 32px;
  margin-bottom: 8px;
}

.dynamicBidBtn,
.dynamicBidBtn:hover,
.dynamicBidBtn:active,
.dynamicBidBtn:focus {
  width: 176px;
  height: 50px;
  background: #333333 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
  border: none;
  border-radius: 4px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.placedBids > table > thead > tr {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  border: none !important;
}

.placedBids > table > tbody > tr {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
}

.winnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.winnerWrapper > .winnerCard {
  width: 519px !important;
  /* height    : 382px; */
  width: 519px;
  /* height    : 382px; */
  text-align: center;
}
.autionwinnerDetailsunsold {
  justify-content: center;
}

.winnerCard > .card-body {
  padding: 32px 32px 24px;
}

.winnerName {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
}

ul {
  /* list-style: square inside url("./../"); */
  justify-content: space-between;
  display: flex;
  list-style: circle;
}

.autionwinnerDetails > li {
  padding-bottom: 20px;
  text-align: left;
}

.paymentLinkBtn {
  width: 240px;
  height: 38px;
  background: #333333;
  border: none;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
}

.downloadInvoiceBtn {
  width: 174px;
  height: 38px;
  border: none;
  background: #000000;
  opacity: 0.2;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
}

.paymentLinkBtn:hover,
.paymentLinkBtn:active,
.paymentLinkBtn:focus {
  background-color: #000000 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.downloadInvoiceBtn:hover,
.downloadInvoiceBtn:active,
.downloadInvoiceBtn:focus {
  background-color: #000000 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.cardButtonRow {
  justify-content: space-between;
}

.videosIMage img {
  width: 203px;
  height: 149px;
}

.videosIMage svg {
  margin-top: -180px;
  height: 82px;
  margin-left: 56px;
  border: 3px solid #000;
  border-radius: 50%;
  padding: 10px;
}

.disableContent .public-DraftEditor-content[contenteditable="true"] {
  -webkit-user-modify: unset !important;
}
.Paginationbtns {
  justify-content: center;
}

.description_tabs_content {
  padding-top: 30px;
}
.description_tabs_content .prd_des_list {
  width: 70%;
  border-bottom: 1px solid #c4c4c4;
}

.description_tabs_content .prd_des_list .nav-item {
  margin-left: 50px;
  border: 0;
  font-family: "Noto Serif", serif;
  font-style: normal;
  color: #333333;
  font-weight: 600;
  font-size: 18px;
  display: block;
  padding: 0;
  padding-bottom: 10px;
  line-height: 25px;
  position: relative;
  opacity: 0.4;
  background: transparent;
}

.description_tabs_content .prd_des_list .nav-item.active::before {
  content: "";
  background: #212121;
  width: 100%;
  height: 2px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.description_tabs_content .prd_des_list .nav-item.active {
  opacity: 1;
}

.description_tabs_content .tab-content {
  padding: 20px 50px;
  font-family: "Noto Serif", serif;
}

.description_tabs_content .tab-content .des_tab_cont p {
  padding-bottom: 25px;
  color: #333333;
}

.description_tabs_content .tab-content .des_tab_cont h4 {
  font-size: 20px;
  color: #333333;
  font-weight: 400;
}

.des_tab_cont .public-DraftEditor-content[contenteditable="true"] {
  -webkit-user-modify: unset !important;
}
.tabs-content-section-view-main {
  font-family: "Noto Serif";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #111111;
  /* margin-bottom: 30px; */
}
.tabs-content-section-view {
  margin-bottom: 20px;
  margin-top: 48px;

  font-family: "Noto Serif";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;

  color: #111111;
}
.other-items-list-by-sec > li > div {
  display: flex;
  line-height: 35px;
}
.other-items-list-by-sec .other-item-key-val {
  width: 170px;
  min-width: 170px;
}
.other-items-list-by-sec .other-item-collen-val {
  width: 50px;
  min-width: 50px;

  text-align: center;
}
/* media query css start */
.lot-details-1 .description_tabs_content * {
  font-family: Noto Serif;
}
@media (max-width: 991px) {
  .description_tabs_content .tab-content {
    padding: 20px 15px;
  }
  .description_tabs_content .prd_des_list {
    width: 100%;
  }
  .description_tabs_content .prd_des_list .nav-item {
    margin-left: 32px;
  }
  .description_tabs_content .prd_des_list .nav-item:first-child {
    margin-left: 15px;
  }
}

@media (max-width: 600px) {
  .description_tabs_content .prd_des_list .nav-item {
    font-weight: 500;
    font-size: 14px;
  }
  .description_tabs_content .tab-content .des_tab_cont p {
    font-size: 14px;
  }
  .other-items-list-by-sec > li {
    margin-bottom: 10px;
    display: block;
  }
  .other-items-list-by-sec > li > div {
    display: block;
  }
  .other-items-list-by-sec .other-item-collen-val {
    display: none;
  }
  .other-items-list-by-sec .other-item-key-val {
    font-weight: bold;
  }
  .other-items-list-by-sec .other-item-key-val span {
    display: inline-block !important;
  }
  .other-items-list-by-sec .other-item-collen-val-val {
    line-height: 25px;
  }
  .other-items-list-by-sec {
    padding-left: 10px;
  }
}
.description_tabs_content .other-items-list-by-sec1 {
  display: block;
}

/* media query css end */
