.CreateRoleContainer {
  margin-top: 52px;
  margin-left: 50px;
}

.roleInfoHeadingRow {
  margin-top: 32px !important;
  margin-left: 75px !important;
}

.roleMgmtHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #333333;
  cursor: pointer;
}

.createNewRoleHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  color: #000000;
}

.roleSubHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
  margin-top: 71px !important;
  border-bottom: 1px solid #b0b0b0;
}

.CreateRoleContainer > .createNewRole {
  width: 860px !important;
  margin: auto;
}

.createNewRoleBody {
  padding-left: 23px !important;
  padding-bottom: 73px !important;
}

.form-check-label {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
}

.cancelCreate {
  margin-bottom: 136px !important;
  margin-right: 215px !important;
  margin-top: 56px !important;
  justify-content: flex-end;
}

.cancelBtn {
  width: 116px;
  height: 50px;
  border: 1px solid #000000;
  background-color: #ffffff !important;
  color: #000000;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.cancelBtn:hover,
.cancelBtn:focus,
.cancelBtn:active {
  color: #000000 !important;
  border: 1px solid #000000 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.createBtn {
  width: 131px;
  height: 50px;
  background: #000000;
  border: none;
  border-radius: 4px;
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  margin-left: 16px;
}

.createBtn:hover,
.createBtn:focus,
.createBtn:active,
.resetPasswordBtn:hover,
.resetPasswordBtn:focus {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
  background: #000000 !important;
}

.roleInformationCard {
  padding: 40px 85px 48px;
  margin-bottom: 36px !important;
}

.imageAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: flex;
  margin: auto;
}

.roleInfoName {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #333333;
  text-align: center;
  margin-top: 16px;
}

.noPadding {
  padding: 0;
}

.noPadding > p {
  margin-bottom: 0;
}

.fieldValues {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  color: #333333;
}

.resetPasswordBtn {
  width: 159px;
  height: 38px;
  background: #000000;
  border: none;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  margin-top: 40px;
}
.invalidEmailClass {
  color: red;
  font-size: 14px;
}
