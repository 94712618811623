.siteInnerContainer {
  background: #f7f7f7;
  border-radius: 4px;
  margin: 80px 207px 188px !important;
  justify-content: center;
  display: flex;
  align-items: center;
}

.siteInnerBox {
  width: 361px;
  height: 232px;
  background: #e7e7e7;
  border-radius: 6px;
  margin-top: 113px;
  margin-bottom: 113px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.leftBoxSpacing {
  margin-right: 57px;
}

.siteInnerBox > .headingText {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #333333;
  margin-top: 19px;
  margin-bottom: 33px;
}

.smEditBtn {
  width: 110px;
  height: 42px;
  background: #000000;
  border: none;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.smEditBtn:hover,
.smEditBtn:focus,
.smEditBtn:active {
  background: #000000 !important;
  color: #ffffff !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}
