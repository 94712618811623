.angleUpIcon {
  float: right;
}

.formHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
}

.collectionOverviewBody {
  padding-left: 33px !important;
  padding-right: 33px !important;
  /* max-width: 300px; */
  overflow-y: scroll;
}

.collectionOverviewBody::-webkit-scrollbar {
  width: 3px;
  margin-top: 10px;
  border-radius: 3px;
  background-color: #aaa;
  overflow: hidden;
  overflow-y: scroll;
}

.collectionOverviewBody::-webkit-scrollbar-track {
  background: #ffffff;
}

.collectionOverviewBody::-webkit-scrollbar-thumb {
  background: #979797;
}

.textArea {
  width: 728px;
}
h2 {
  text-align: center;
}
.editor {
  /* width: 500px; */
  border: 1px solid #e2e2e2;
}
.myCLass {
  width: 600px;
  float: right;
}
#previewHtml {
  width: 500px;
}
/* .rdw-editor-toolbar div{
    display: inline;
} */
