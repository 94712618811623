.createLotContainer {
  margin-top: 64px;
  margin-left: 50px;
}

.card {
  width: 860px;
  height: auto;
  margin-bottom: 40px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.08);
}

#Accordion_Section_0,
#Accordion_Section_1,
#Accordion_Section_2,
#Accordion_Section_3,
#Accordion_Section_4,
#Accordion_Section_5,
#Accordion_Section_6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headingRow {
  margin: 0;
  display: flex;
  align-items: center;
  margin-bottom: 48px;
}

.lotManagementHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  margin-left: 5px;
}

.formsRow {
  justify-content: center;
}

.sectionHeading {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 34px;
  color: #000000;
  padding-top: 48px;
  padding-bottom: 31px;
}

.backBtnColumn {
  display: flex;
  justify-content: flex-end;
}

.addMoreBtnColumn {
  display: flex;
  justify-content: flex-end;
}

.btnGroup {
  width: 860px;
  display: flex;
  justify-content: space-between;
  margin-left: 221px !important;
  margin-top: 80px !important;
  margin-bottom: 86px !important;
}

.backToCollection {
  width: 225px;
  height: 50px;
  border: 1px solid #000000;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  background: #fff;
  color: #000000;
}

.addMoreLotsBtn {
  width: 149px;
  height: 50px;
  background: #000000;
  border-radius: 4px;
  border: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.finishBtn {
  width: 110px;
  height: 50px;
  border: 1px solid #000000;
  border-radius: 4px;
  background: #ffffff;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.backToCollection:focus,
.backToCollection:hover,
.backToCollection:active,
.finishBtn:focus,
.finishBtn:hover,
.finishBtn:active {
  border: 1px solid #000000 !important;
  background: #fff !important;
  color: #000000 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.addMoreLotsBtn:focus,
.addMoreLotsBtn:hover,
.addMoreLotsBtn:active {
  background: #000000 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}
.createlotContainPageHide,
.previewContainPageHide {
  display: none;
}
