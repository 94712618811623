.listViewContainer {
  width: 100%;
  margin-bottom: 49px;
}

.listImage {
  width: 302px;
  height: 200px;
}

.dottedLineList {
  margin-left: 229px;
  margin-top: 16px;
}

.lotTitle {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #333333;
  margin-bottom: 10px;
}

.estimate {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.5);
  margin: 0;
}

.lotBtn {
  width: 59px;
  height: 33px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  margin: 8px 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.04em;
  color: #333333;
}

.viewListEdit {
  width: 127px;
  height: 37px;
  background: #ffffff;
  border: 1px solid #333333;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #333333;
  margin-top: 89px;
}

.viewListEdit:hover,
.viewListEdit:focus,
.viewListEdit:active {
  border: 1px solid #333333 !important;
  background-color: #333333 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}
